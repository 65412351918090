import React from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

import BreadcrumbsMenu from 'client/app/components/nav/breadcrumbs/components/BreadcrumbsMenu';
import stopPropagation from 'common/lib/stopPropagation';
import { usePopover } from 'common/ui/hooks/usePopover';

export default function ItemsDropdown({ children }: React.PropsWithChildren<{}>) {
  const { popoverAnchorElement, isPopoverOpen, onShowPopover, onHidePopover } =
    usePopover();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    stopPropagation(event);
    onShowPopover(event);
  };
  const handleAnyMenuClick = (event: React.MouseEvent) => {
    stopPropagation(event);
    onHidePopover();
  };

  return (
    <>
      <ArrowIconButton
        data-heap-tracking="breadcrumbs-items-dropdown-button"
        onClick={handleClick}
      >
        <ArrowDropDownIcon />
      </ArrowIconButton>
      <BreadcrumbsMenu
        data-heap-tracking="breadcrumbs-items-dropdown-menu"
        anchorEl={popoverAnchorElement}
        open={isPopoverOpen}
        onClose={onHidePopover}
        onClick={handleAnyMenuClick}
        onDoubleClick={stopPropagation}
      >
        {children}
      </BreadcrumbsMenu>
    </>
  );
}

const ArrowIconButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  color: theme.palette.text.primary,
}));
