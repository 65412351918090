import React from 'react';

import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';

import { isNumericFactor } from 'client/app/components/DOEBuilder/factorUtils';
import useHasDerivedFactors from 'client/app/components/DOEBuilder/useHasDerivedFactors';
import DerivedLevelEditor from 'client/app/components/DOEFactorForm/components/DerivedLevelEditor';
import DOEForm from 'client/app/components/DOEFactorForm/components/DOEForm';
import FactorDeriveSelect from 'client/app/components/DOEFactorForm/components/FactorDeriveSelect';
import FactorDerivingExpression from 'client/app/components/DOEFactorForm/components/FactorDerivingExpression';
import FactorLevelEditor from 'client/app/components/DOEFactorForm/components/FactorLevelEditor';
import FactorNameEditor from 'client/app/components/DOEFactorForm/components/FactorNameEditor';
import { DOEFactorSampling } from 'client/app/components/DOEFactorForm/components/FactorSampling';
import Help from 'client/app/components/DOEFactorForm/components/Help';
import SimpleMutualExclusionFactorLevelEditor from 'client/app/components/DOEFactorForm/components/SimpleMutualExclusionFactorLevelEditor';
import {
  FactorParameterInfo,
  MutualExclusionInfo,
} from 'client/app/components/DOEFactorForm/types';
import useDOEFactorForm, {
  mapStateToFactor,
  mapStateToMutualExclusionFactors,
} from 'client/app/components/DOEFactorForm/useDOEFactorForm';
import { ParameterHeader } from 'client/app/components/Parameters/ElementParameterHeader';
import { FactorItem } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import Checkbox from 'common/ui/components/Checkbox';
import ItemList, { Item } from 'common/ui/components/ItemList/ItemList';
import TextField from 'common/ui/filaments/TextField';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  className?: string;
  onCancel?: () => void;
  isReadonly: boolean;
  parameterInfo?: FactorParameterInfo;
  factor?: FactorItem;
  onSave?: (factors: FactorItem[], factorsToRemove?: string[]) => void;
  factorDescriptors: string[];
  existingGroupNames: string[];
  mutualExclusion?: MutualExclusionInfo;
  derivableFactors?: FactorItem[];
  typeToAdd: 'numerical-factor' | 'categorical-factor' | 'derived';
  group?: FactorItem[];
};

const DOEFactorForm = ({
  className,
  onCancel,
  onSave,
  isReadonly,
  parameterInfo,
  factor,
  factorDescriptors,
  existingGroupNames,
  mutualExclusion,
  derivableFactors,
  typeToAdd,
  group,
}: Props) => {
  const classes = useStyles();

  const {
    state,
    updateFactor,
    updateLevels,
    updateIsSimpleMutualExclusion,
    updateSimpleMutualExclusionLevelDetails,
    updateDerivingExpression,
    updateSourceFactor,
    mapFactorLevels,
    showErrors,
  } = useDOEFactorForm(
    parameterInfo,
    factor,
    factorDescriptors,
    existingGroupNames,
    typeToAdd,
    mutualExclusion,
    group,
  );

  const errors = state.showErrors ? state.errors : {};

  const isNumeric = isNumericFactor(state.factor);
  const isDerived = state.factor.variableTypeName === 'derived';
  const isQuasiRep = state.factor.variableTypeName === 'quasi-replicating';
  const sourceFactorItem = derivableFactors?.find(
    f => f.id === state.factor.sourceFactor?.id,
  );

  const updateSingleLevel = (item: Item) => (value: string | undefined) =>
    updateLevels(
      state.levels.map(l => (l.id === item.id ? { id: l.id, value: value ?? '' } : l)),
    );
  const updateSingleMutualExclusionLevelDetail =
    (item: Item) => (property: 'factorValue' | 'unit', value: string | undefined) => {
      updateSimpleMutualExclusionLevelDetails(
        state.simpleMutualExclusionLevelDetails.map(l =>
          l.id === item.id ? { ...l, [property]: value ?? '' } : l,
        ),
      );
    };
  const handleSubmit = () => {
    if (!isReadonly) {
      if (state.errors) {
        showErrors();
      } else {
        if (state.isSimpleMutualExclusion) {
          const factorsToRemove = state.isNew ? undefined : [state.factor.id];
          onSave?.(
            mapStateToMutualExclusionFactors(state, parameterInfo),
            factorsToRemove,
          );
        } else {
          const factorsToRemove = state.isNew
            ? undefined
            : state.simpleMutualExclusionLevelDetails.map(details => details.id);
          onSave?.([mapStateToFactor(state, parameterInfo)], factorsToRemove);
        }
      }
    }
  };
  const formActions = (
    <>
      <Button variant="tertiary" size="small" type="button" onClick={() => onCancel?.()}>
        Cancel
      </Button>
      <Button
        variant="secondary"
        color="primary"
        size="small"
        type="submit"
        disabled={isReadonly}
      >
        {state.isNew ? 'Add' : 'Save'} Factor
      </Button>
    </>
  );

  const showFactorName = !state.isSimpleMutualExclusion;
  const showReplicateMessage = !!mutualExclusion?.replicate;

  const isCustomCategoricalFactor = state.isCustom && typeToAdd === 'categorical-factor';

  const showParameterFactorUnit = !!state.unitOptions && !state.isSimpleMutualExclusion;
  const showCustomFactorUnit =
    !showParameterFactorUnit &&
    isNumeric &&
    state.isCustom &&
    !state.isSimpleMutualExclusion;
  const showFactorLevels = !isDerived;

  const isParameterSimpleMutualExclusion =
    !state.isCustom && state.isSimpleMutualExclusion;

  const showQuasiReplicate =
    !mutualExclusion &&
    (state.factor.variableTypeName === 'factor' ||
      state.factor.variableTypeName === 'quasi-replicating') &&
    !isParameterSimpleMutualExclusion;

  const showHardToChange = showQuasiReplicate;

  const showSampling =
    isNumeric && !isDerived && !mutualExclusion && !state.isSimpleMutualExclusion;
  const showDerivingExpression = isNumeric && isDerived;
  const showDeriveFromFactor = !isNumeric && isDerived;
  const showDerivedLevels = showDeriveFromFactor && !!sourceFactorItem;

  const { isPartOfDerivingExpression, isDerivationSourceFactor } = useHasDerivedFactors(
    factor?.id,
  );

  const disableNameEditing =
    isReadonly || isPartOfDerivingExpression || isDerivationSourceFactor;
  const disableLevelEditing = isReadonly || isDerivationSourceFactor;

  const disableQuasiRep = isReadonly || state.isSimpleMutualExclusion;
  const disableHardToChange =
    isReadonly ||
    state.factor.variableTypeName !== 'factor' ||
    state.isSimpleMutualExclusion;
  const disableSampling = isReadonly || isQuasiRep || state.factor.hardToChange;
  const disableSimpleMutualExclusionCheckbox =
    isReadonly ||
    state.factor.hardToChange ||
    state.factor.variableTypeName === 'quasi-replicating';

  return (
    <DOEForm
      className={className}
      classes={{ body: classes.container }}
      onSubmit={handleSubmit}
      actions={formActions}
    >
      {showFactorName && (
        <div className={classes.field}>
          <ParameterHeader
            displayName="Factor Name"
            isRequired
            help={
              isCustomCategoricalFactor
                ? Help.customCategoricalFactorNameHelp
                : Help.factorNameHelp
            }
          />
          <FactorNameEditor
            displayName={state.factor.displayName}
            onChange={name => updateFactor({ displayName: name ?? '' })}
            errors={errors}
            parameter={parameterInfo}
            disabled={disableNameEditing}
          />
        </div>
      )}
      {state.isSimpleMutualExclusion && (
        <div className={classes.field}>
          <ParameterHeader
            displayName="Name"
            isRequired
            help={Help.simpleMutualExclusionNameHelp}
          />
          <TextField
            value={state.factor.displayName}
            onChange={e => updateFactor({ displayName: e.target.value ?? '' })}
            placeholder="Name"
            error={!!errors?.name}
            helperText={errors?.name}
            disabled={isReadonly}
          />
        </div>
      )}
      {showReplicateMessage && (
        <Typography className={classes.replicateMessage}>
          <InfoOutlined fontSize="small" />
          The following settings will be replicated across
          <br />
          all the factors in the mutual exclusion group.
        </Typography>
      )}
      {showParameterFactorUnit && (
        <div className={classes.field}>
          <ParameterHeader displayName="Unit" isRequired />
          <Autocomplete
            autoSelect
            disableClearable
            disabled={isReadonly}
            options={state.unitOptions ?? []}
            value={state.factor.unit}
            onChange={(_, value) => updateFactor({ unit: value })}
            placeholder="Unit"
            renderInput={params => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </div>
      )}
      {showCustomFactorUnit && (
        <div className={classes.field}>
          <ParameterHeader displayName="Unit" isRequired={false} />
          <TextField
            value={state.factor.unit}
            onChange={e => updateFactor({ unit: e.target.value })}
            placeholder="Unit"
            disabled={isReadonly}
          />
        </div>
      )}
      {isCustomCategoricalFactor && (
        <div className={classes.checkboxOptions}>
          <div className={classes.field}>
            <FormControlLabel
              className={classes.checkboxOption}
              classes={{ label: classes.checkboxOptionLabel }}
              control={
                <Checkbox
                  checked={state.isSimpleMutualExclusion}
                  onChange={(_, value) => updateIsSimpleMutualExclusion(value)}
                  disabled={disableSimpleMutualExclusionCheckbox}
                />
              }
              label={
                <>
                  Add quantities
                  {Help.addQuantitiesHelp}
                </>
              }
            />
          </div>
        </div>
      )}
      {showFactorLevels && (
        <div className={classes.field}>
          <ParameterHeader
            displayName="Levels"
            isRequired
            help={
              state.isSimpleMutualExclusion
                ? Help.simpleMutualExclusionFactorLevelsHelp
                : isNumeric
                ? Help.factorLevelsHelp
                : Help.categoricalFactorLevelsHelp
            }
          />
          <ItemList
            value={state.levels}
            onChange={updateLevels}
            disabled={disableLevelEditing}
            renderItemEditor={(item, autoFocus, onPressEnter) =>
              state.isSimpleMutualExclusion ? (
                <SimpleMutualExclusionFactorLevelEditor
                  value={item.value}
                  details={state.simpleMutualExclusionLevelDetails.find(
                    levelDetails => levelDetails.id === item.id,
                  )}
                  onChange={updateSingleLevel(item)}
                  onLevelDetailChange={updateSingleMutualExclusionLevelDetail(item)}
                  autoFocus={autoFocus}
                  onPressEnter={onPressEnter}
                  parameterInfo={parameterInfo}
                  disabled={disableLevelEditing}
                  error={!!errors?.levelValues?.[item.id]}
                  helperText={errors?.levelValues?.[item.id]}
                  factorValueError={!!errors?.levelFactorValues?.[item.id]}
                  factorValueHelperText={errors?.levelFactorValues?.[item.id]}
                  unitError={!!errors?.levelUnits?.[item.id]}
                  unitHelperText={errors?.levelUnits?.[item.id]}
                  unitOptions={state.unitOptions}
                />
              ) : (
                <FactorLevelEditor
                  value={item.value}
                  onChange={updateSingleLevel(item)}
                  autoFocus={autoFocus}
                  onPressEnter={onPressEnter}
                  parameterInfo={parameterInfo}
                  disabled={disableLevelEditing}
                  error={!!errors?.levelValues?.[item.id]}
                  helperText={errors?.levelValues?.[item.id]}
                />
              )
            }
          />

          {!!errors?.levels && <FormHelperText error>{errors?.levels}</FormHelperText>}
        </div>
      )}

      {(showHardToChange || showQuasiReplicate) && (
        <div className={classes.checkboxOptions}>
          {showQuasiReplicate && (
            <div className={classes.field}>
              <FormControlLabel
                className={classes.checkboxOption}
                classes={{ label: classes.checkboxOptionLabel }}
                control={
                  <Checkbox
                    checked={state.factor.variableTypeName === 'quasi-replicating'}
                    onChange={(_, value) =>
                      updateFactor({
                        variableTypeName: value ? 'quasi-replicating' : 'factor',
                        hardToChange: false,
                      })
                    }
                    disabled={disableQuasiRep}
                  />
                }
                label={
                  <>
                    Quasi-Replicate
                    {Help.quasiReplicateHelp}
                  </>
                }
              />
            </div>
          )}

          {showHardToChange && (
            <div className={classes.field}>
              <FormControlLabel
                className={classes.checkboxOption}
                classes={{ label: classes.checkboxOptionLabel }}
                control={
                  <Checkbox
                    checked={state.factor.hardToChange}
                    onChange={(_, value) => updateFactor({ hardToChange: value })}
                    disabled={disableHardToChange}
                  />
                }
                label={
                  <>
                    Hard to change
                    {Help.hardToChangeHelp}
                  </>
                }
              />
            </div>
          )}
        </div>
      )}

      {showSampling && (
        <DOEFactorSampling
          factor={state.factor}
          errors={errors}
          onUpdate={updateFactor}
          disabled={disableSampling}
          showNumberOfZeros={state.factor.sampleMode !== 'discrete'}
        />
      )}
      {showDerivingExpression && !!derivableFactors?.length && (
        <div className={classes.field}>
          <ParameterHeader
            displayName="Equation"
            isRequired
            help={Help.derivingExpressionHelp}
          />
          <FactorDerivingExpression
            value={state.factor.derivingExpression}
            onChange={updateDerivingExpression}
            factors={derivableFactors}
            errorMsg={errors?.derivingExpression}
            isReadonly={isReadonly}
          />
        </div>
      )}
      {showDeriveFromFactor && !!derivableFactors?.length && (
        <div className={classes.field}>
          <ParameterHeader
            displayName="Derive From Factor"
            isRequired
            help={Help.sourceFactorHelp}
          />
          <FactorDeriveSelect
            value={state.factor.sourceFactor}
            onChange={updateSourceFactor}
            factors={derivableFactors}
            errorMsg={errors?.sourceFactor}
            isReadonly={isReadonly}
          />
        </div>
      )}
      {showDerivedLevels && (
        <div className={classes.field}>
          <ParameterHeader
            displayName="Levels"
            isRequired
            help={Help.derivedLevelsHelp}
          />
          <ItemList
            value={state.levels}
            onChange={updateLevels}
            disabled={isReadonly}
            renderItemEditor={(item, autoFocus, onPressEnter) => (
              <DerivedLevelEditor
                value={item.value}
                onChange={updateSingleLevel(item)}
                onPressEnter={onPressEnter}
                onMultiselectChange={selected => mapFactorLevels(selected, item.value)}
                autoFocus={autoFocus}
                parameterInfo={parameterInfo}
                deriveFrom={sourceFactorItem}
                sourceFactorValueMap={state.levelMapping}
                disabled={isReadonly}
                error={!!errors?.levelValues?.[item.id]}
                helperText={errors?.levelValues?.[item.id]}
              />
            )}
          />
          {errors?.unmappedLevels && (
            <FormHelperText error>
              {`${errors.unmappedLevels} of "${sourceFactorItem.displayName}" has not been assigned to a level in this factor. All levels of the derived-from factor must be assigned.`}
            </FormHelperText>
          )}
          {errors?.levels && <FormHelperText error>{errors?.levels}</FormHelperText>}
        </div>
      )}
    </DOEForm>
  );
};

export default DOEFactorForm;

const useStyles = makeStylesHook(({ spacing }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(5),
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
  },
  checkboxOptions: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2),
  },
  checkboxOption: {
    alignSelf: 'start',
  },
  checkboxOptionLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing(3),
  },
  replicateMessage: {
    display: 'flex',
    gap: spacing(5),
    padding: spacing(3, 10, 3, 5),
    background: Colors.BLUE_5,
    borderRadius: spacing(2),
    alignItems: 'center',
  },
}));
