import React, { useMemo } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import Transition from 'react-transition-group/Transition';

import { PANEL_TRANSITION } from 'client/app/components/DOEBuilder/factorUtils';
import DOEForm from 'client/app/components/DOEFactorForm/components/DOEForm';
import { DOEFactorSampling } from 'client/app/components/DOEFactorForm/components/FactorSampling';
import DOEFactorForm from 'client/app/components/DOEFactorForm/DOEFactorForm';
import {
  FactorParameterInfo,
  MutualExclusionInfo,
} from 'client/app/components/DOEFactorForm/types';
import useDOEMutualExclusionFormState from 'client/app/components/DOEMutualExclusionForm/useDOEMutualExclusionForm';
import { ParameterHeader } from 'client/app/components/Parameters/ElementParameterHeader';
import { FactorItem } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import Checkbox from 'common/ui/components/Checkbox';
import IconButton from 'common/ui/components/IconButton';
import InlineHelp from 'common/ui/components/InlineHelp/InlineHelp';
import TextField from 'common/ui/filaments/TextField';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  className?: string;
  isReadonly: boolean;
  parameterInfo?: FactorParameterInfo;
  group?: FactorItem[];
  factorDescriptors: string[];
  onCancel?: () => void;
  onSave?: (group: FactorItem[]) => void;
  existingGroupNames: string[];
  initialEditFactor?: FactorItem;
};

const DOEMutualExclusionForm = ({
  className,
  isReadonly,
  parameterInfo,
  group,
  factorDescriptors,
  onCancel,
  onSave,
  existingGroupNames,
  initialEditFactor,
}: Props) => {
  const classes = useStyles();
  const {
    state,
    updateName,
    updateSampleMode,
    addFactor,
    deleteFactor,
    selectFactor,
    setReplicateLevels,
    saveFactor,
    cancelEditing,
    showErrors,
    errors,
  } = useDOEMutualExclusionFormState(
    parameterInfo,
    group,
    existingGroupNames,
    initialEditFactor,
  );

  const isEditing = state.addingFactor || !!state.selectedFactor;

  const errorsToShow = state.showErrors ? errors : {};

  const infoForFactorForm = useMemo<MutualExclusionInfo>(
    () => ({
      groupName: state.name,
      replicate: state.replicateLevels ? state.factors[0] : undefined,
      levelCount: state.factors[0]?.values.length,
    }),
    [state.factors, state.name, state.replicateLevels],
  );

  return (
    <div className={cx(className, classes.wrapper)}>
      <DOEForm
        className={cx(classes.form, {
          [classes.editingFactor]: isEditing,
        })}
        classes={{
          bodyWrapper: isEditing ? classes.editingFormBody : undefined,
          body: classes.container,
        }}
        onSubmit={() => {
          if (!errors) {
            onSave?.(state.factors);
          } else {
            showErrors();
          }
        }}
        actions={
          <>
            <Button
              variant="tertiary"
              size="small"
              type="button"
              onClick={() => onCancel?.()}
            >
              Cancel
            </Button>
            <Button
              variant="secondary"
              color="primary"
              size="small"
              type="submit"
              disabled={isReadonly}
            >
              {state.isNew ? 'Add' : 'Save'} Mutual Exclusion
            </Button>
          </>
        }
      >
        <div className={classes.field}>
          <ParameterHeader
            displayName="Name"
            isRequired
            help={
              <InlineHelp heading="Mutual Exclusion Name">
                <p>
                  Mutual exclusion allows you to specify more than one factor and their
                  levels as part of a group, such that any run in your design will only
                  contain one of these factors at a time.
                </p>
                <p>
                  For example, you may call your mutual exclusion group “Carbon Source”,
                  and define factors called Glucose, Glycerol or Galactose with each
                  having their own levels to sample from. In any run in your design you
                  will only get Glucose, Glycerol or Galactose at one of its levels but
                  never more than one carbon source at a time.
                </p>
              </InlineHelp>
            }
          />
          <TextField
            value={state.name}
            onChange={e => updateName(e.target.value)}
            placeholder="Name"
            error={!!errorsToShow?.name}
            helperText={errorsToShow?.name}
            disabled={isReadonly}
          />
        </div>
        <FormControlLabel
          classes={{ label: classes.replicateCheckLabel }}
          control={
            <Checkbox
              value={state.replicateLevels}
              onChange={e => setReplicateLevels(e.currentTarget.checked)}
            />
          }
          label={
            <>
              Replicate levels across all factors
              <InlineHelp heading="Replicate levels across all factors">
                <p>
                  If all of your factors share the same units and sampling range, define
                  the units and levels in one go.
                </p>
                <p>
                  If the factors in your mutual exclusion group have different units or
                  requirements to sample different ranges then specify these separately
                  for each factor.
                </p>
              </InlineHelp>
            </>
          }
        />
        <div className={classes.field}>
          <ParameterHeader displayName="Factors" isRequired />

          {errors?.levelCount && (
            <FormHelperText error className={classes.levelsError}>
              {errors.levelCount}
            </FormHelperText>
          )}

          <ol className={classes.factorsList}>
            {state.factors.map(factor => (
              <li
                className={cx(classes.factor, {
                  [classes.selectedFactor]: state.selectedFactor?.id === factor.id,
                })}
                key={factor.id}
                onClick={() => selectFactor(factor)}
              >
                <Typography variant="subtitle2">{factor.displayName}</Typography>

                <Typography
                  variant="body2"
                  color={errors?.levelCount ? 'error' : 'textSecondary'}
                >
                  {factor.values.length} Levels
                </Typography>

                <IconButton
                  size="xsmall"
                  icon={<CloseIcon fontSize="small" color="error" />}
                  onClick={e => {
                    e.stopPropagation();
                    deleteFactor(factor.id);
                  }}
                />
              </li>
            ))}
          </ol>

          <Button
            className={classes.addButton}
            variant="tertiary"
            color="primary"
            onClick={addFactor}
            startIcon={<AddIcon />}
          >
            Add New Factor
          </Button>

          {!!errorsToShow?.factors && (
            <FormHelperText error>{errorsToShow?.factors}</FormHelperText>
          )}
        </div>
        <DOEFactorSampling
          factor={{ sampleMode: state.sampleMode } as FactorItem}
          onUpdate={({ sampleMode }) => sampleMode && updateSampleMode(sampleMode)}
          disabled={isReadonly}
          showNumberOfZeros={false}
        />
      </DOEForm>

      <Transition
        in={isEditing}
        timeout={PANEL_TRANSITION}
        appear
        mountOnEnter
        unmountOnExit
      >
        {tState => (
          <DOEFactorForm
            className={cx(classes.factorForm, {
              visible: tState === 'entering' || tState === 'entered',
            })}
            isReadonly={isReadonly}
            factor={state.addingFactor ? undefined : state.selectedFactor!}
            factorDescriptors={factorDescriptors}
            existingGroupNames={[]}
            parameterInfo={parameterInfo}
            onCancel={cancelEditing}
            onSave={factorArray => saveFactor(factorArray[0])}
            mutualExclusion={infoForFactorForm}
            typeToAdd="numerical-factor"
          />
        )}
      </Transition>
    </div>
  );
};

const useStyles = makeStylesHook(({ palette, spacing }) => ({
  wrapper: {
    display: 'grid',
    placeItems: 'stretch',
    gridTemplate: 'minmax(0, 1fr) / 1fr',
  },
  form: {
    transition: `all ${PANEL_TRANSITION}ms ease-in`,
  },
  editingFactor: {
    transition: `all ${PANEL_TRANSITION}ms ease-in`,
    pointerEvents: 'none',
    transform: 'translateX(-20%)',
  },
  editingFormBody: {
    transition: `all ${PANEL_TRANSITION}ms ease-in`,
    opacity: 0.5,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(5),
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
  },
  factorsHead: {
    paddingBottom: 0,
  },
  factors: {
    display: 'flex',
    flexDirection: 'column',
  },
  factorsList: {
    display: 'grid',
    flexDirection: 'column',
    listDisplayType: 'none',
    padding: 0,
    margin: 0,
    gap: spacing(3),
  },
  factor: {
    display: 'grid',
    gridTemplate: 'auto / 1fr auto auto',
    gap: spacing(5),
    alignItems: 'center',
    padding: spacing(4, 4, 4, 5),
    border: `1px solid ${Colors.GREY_30}`,
    boxShadow: `0px 1px 2px 0px rgba(0, 0, 0, 0.12)`,
    borderRadius: '4px',
    background: Colors.GREY_0,
    cursor: 'pointer',
    position: 'relative',

    '&:hover': {
      background: Colors.BLUE_0,
    },
  },
  levelsError: {
    marginBottom: spacing(3),
  },
  selectedFactor: {
    borderColor: 'transparent',
    outline: `2px solid ${palette.primary.main}`,
  },
  addButton: {
    marginTop: spacing(3),
  },
  factorForm: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: 'min(100%, 480px)',
    zIndex: 10,
    transform: 'translateX(100%)',
    transition: `transform ${PANEL_TRANSITION}ms ease-in`,
    '&.visible': {
      transform: 'translateX(0%)',
    },
  },
  replicateCheckLabel: {
    display: 'flex',
    gap: spacing(3),
    alignItems: 'center',
  },
}));

export default DOEMutualExclusionForm;
