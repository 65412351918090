import React, { useState } from 'react';

import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { v4 as uuid } from 'uuid';

import { calculateVolumes } from 'client/app/apps/standalone-tools/stock-dilution-calculator/calculations';
import StockDilutionTargetRow from 'client/app/apps/standalone-tools/stock-dilution-calculator/StockDilutionTargetRow';
import { downloadTextFile } from 'common/lib/download';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import MeasurementEditor from 'common/ui/components/ParameterEditors/MeasurementEditor';
import { getSensibleMeasurementUnits } from 'common/ui/components/ParameterEditors/unitRegistry';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';

export const CONCENTRATION_UNIT_OPTIONS = [
  'Mol/l',
  'mMol/l',
  'uMol/l',
  'nMol/l',
  'g/l',
  'mg/l',
  'ug/l',
  'ng/l',
];

const VOLUME_UNIT_OPTIONS = getSensibleMeasurementUnits('Volume');

const DEFAULT_CONCENTRATION_UNIT = 'Mol/l';

const DEFAULT_VOLUME_UNIT = 'ml';

const PLACEHOLDER_VALUE = 'Value';

export default function StockDilutionCalculator() {
  const snackbar = useSnackbarManager();

  // Stock Concentration and Desired Volume states
  const [stockConcentration, setStockConcentration] = useState<string>('');
  const [desiredVolume, setDesiredVolume] = useState<string>('');

  // Set state for child components
  const [targetRowStates, setTargetRowStates] = useState<
    {
      componentId: string;
      targetConcentration: string;
      stockVolume: string;
      diluentVolume: string;
      errorState: string;
    }[]
  >([
    {
      componentId: uuid(),
      targetConcentration: '',
      stockVolume: '',
      diluentVolume: '',
      errorState: '',
    },
  ]);

  // Helper function for updating state after changes to top-level
  // inputs stockConcentration and desiredVolume
  function updateStateOnInputChange(
    stockVolumeMeasurement: string,
    diluentVolumeMeasurement: string,
    errorMessage: string | undefined,
    targetConcentration: string,
    componentId: string,
  ) {
    setTargetRowStates(prevRows =>
      prevRows.map(row => {
        if (row.componentId !== componentId) {
          return row;
        }
        if (errorMessage) {
          return {
            ...row,
            targetConcentration: targetConcentration,
            stockVolume: '',
            diluentVolume: '',
            errorState: errorMessage,
          };
        } else {
          return {
            ...row,
            targetConcentration: targetConcentration,
            errorState: errorMessage ?? '',
            stockVolume: errorMessage ? '' : stockVolumeMeasurement || '', // Reset or keep previous value
            diluentVolume: errorMessage ? '' : diluentVolumeMeasurement || '', // Reset or keep previous value
          };
        }
      }),
    );
  }

  // Set state of top-level inputs Stock Concentration and Desired Volume
  const handleSetStockConcentration = (newValue: string | undefined) => {
    setStockConcentration(newValue ?? '');
    if (newValue) {
      targetRowStates.forEach(targetRowState => {
        const { diluentVolumeMeasurement, stockVolumeMeasurement, errorMessage } =
          calculateVolumes(newValue, desiredVolume, targetRowState.targetConcentration);

        updateStateOnInputChange(
          stockVolumeMeasurement,
          diluentVolumeMeasurement,
          errorMessage,
          targetRowState.targetConcentration,
          targetRowState.componentId,
        );
      });
    }
  };

  const handleSetDesiredVolume = (newValue: string | undefined) => {
    setDesiredVolume(newValue ?? '');
    if (newValue) {
      targetRowStates.forEach(targetRowState => {
        const { diluentVolumeMeasurement, stockVolumeMeasurement, errorMessage } =
          calculateVolumes(
            stockConcentration,
            newValue,
            targetRowState.targetConcentration,
          );

        updateStateOnInputChange(
          stockVolumeMeasurement,
          diluentVolumeMeasurement,
          errorMessage,
          targetRowState.targetConcentration,
          targetRowState.componentId,
        );
      });
    }
  };

  // Remove child components from list of rows
  const handleRemoveRow = (componentId: string) => {
    setTargetRowStates(
      targetRowStates.filter(
        targetRowState => targetRowState.componentId !== componentId,
      ),
    );
  };

  // Structure a CSV file summarizing the state of child components for download
  const handleOnDownload = () => {
    if (targetRowStates.length === 0) {
      return snackbar.showError(
        'No data to download - please add target/range concentration.',
      );
    }
    const csvFile = createStructuredCSV(
      stockConcentration,
      desiredVolume,
      targetRowStates,
    );
    downloadTextFile(csvFile, 'StockDilutionCalculator.csv');
    snackbar.showSuccess('File created successfully');
  };

  // Create a new child component of type targetRow
  const addTargetRow = () => {
    setTargetRowStates(prevData => [
      ...prevData,
      {
        componentId: uuid(),
        targetConcentration: '',
        stockVolume: '',
        diluentVolume: '',
        errorState: '',
      },
    ]);
  };

  // Event reaction - change in a child component 'target concentration' value.
  // Handle the possibility that the value is unset or an error has occurred
  // whilst trying to calculate an updated value.
  // Update the component state with the new value or return an error message.
  const handleOnSetTargetConcentration = (
    childTargetConcentration: string | undefined,
    componentId: string,
  ) => {
    setTargetRowStates(prevRows =>
      prevRows.map(row => {
        if (row.componentId !== componentId) {
          return row;
        }
        if (!childTargetConcentration) {
          return {
            ...row,
            targetConcentration: '',
            stockVolume: '',
            diluentVolume: '',
            errorState: 'Please set a target concentration.',
          };
        } else {
          const result = calculateVolumes(
            stockConcentration,
            desiredVolume,
            childTargetConcentration,
          );
          return {
            ...row,
            targetConcentration: childTargetConcentration,
            errorState: result?.errorMessage ?? '',
            stockVolume: result.errorMessage ? '' : result.stockVolumeMeasurement || '', // Reset or keep previous value
            diluentVolume: result.errorMessage
              ? ''
              : result.diluentVolumeMeasurement || '', // Reset or keep previous value
          };
        }
      }),
    );
  };

  return (
    <>
      <Grid container direction="row">
        <Grid item />
      </Grid>
      <InputsDiv>
        <CopyTypography>
          The calculator determines the volumes needed of stock/diluent solutions to
          achieve a target concentration in a desired volume. Additional target
          concentrations can be appended and the results copied to your clipboard or
          downloaded in CSV format.
        </CopyTypography>
        <Typography variant="h5">Stock Concentration</Typography>
        <Typography variant="h5">Desired Volume</Typography>
        <MeasurementEditor
          units={CONCENTRATION_UNIT_OPTIONS}
          defaultUnit={DEFAULT_CONCENTRATION_UNIT}
          value={stockConcentration}
          onChange={handleSetStockConcentration}
          placeholder={PLACEHOLDER_VALUE}
          textFieldProps={{
            inputProps: {
              'data-heap-tracking':
                'standalone-tools-stock-dilution-calculator-stock-concentration',
            },
          }}
        />
        <MeasurementEditor
          units={VOLUME_UNIT_OPTIONS}
          defaultUnit={DEFAULT_VOLUME_UNIT}
          value={desiredVolume}
          onChange={handleSetDesiredVolume}
          placeholder={PLACEHOLDER_VALUE}
          textFieldProps={{
            inputProps: {
              'data-heap-tracking':
                'standalone-tools-stock-dilution-calculator-desired-volume',
            },
          }}
        />
      </InputsDiv>
      <ResultsBox>
        <ResultsDiv>
          <ResultHeader>target</ResultHeader>
          <ResultHeader>stock</ResultHeader>
          <ResultHeader>diluent</ResultHeader>
          {targetRowStates.map((targetRowState, index) => {
            return (
              <React.Fragment key={targetRowState.componentId}>
                <StockDilutionTargetRow
                  stockVolume={
                    targetRowState.errorState ? '' : targetRowState.stockVolume || ''
                  }
                  diluentVolume={
                    targetRowState.errorState ? '' : targetRowState.diluentVolume || ''
                  }
                  storedConcentration={targetRowState.targetConcentration}
                  isDisabled={!stockConcentration || !desiredVolume}
                  errorMessage={targetRowState.errorState}
                  onSetTargetConcentration={targetConcentration => {
                    handleOnSetTargetConcentration(
                      targetConcentration,
                      targetRowState.componentId,
                    );
                  }}
                />
                {index === 0 ? (
                  <div />
                ) : (
                  <IconButton
                    onClick={() => handleRemoveRow(targetRowState.componentId)}
                    data-heap-tracking="standalone-tools-stock-dilution-calculator-remove-target"
                  >
                    <DeleteOutlineOutlinedIcon sx={{ color: Colors.ERROR_MAIN }} />
                  </IconButton>
                )}
              </React.Fragment>
            );
          })}
        </ResultsDiv>
        <AddTargetButton
          variant="secondary"
          color="primary"
          disabled={!(stockConcentration && desiredVolume)}
          onClick={addTargetRow}
          data-heap-tracking="standalone-tools-stock-dilution-calculator-add-target"
        >
          add target
        </AddTargetButton>
      </ResultsBox>
      <ButtonDiv>
        <Button
          variant="primary"
          color="primary"
          startIcon={<FileCopyOutlinedIcon />}
          onClick={async () => {
            const clipboardData = formatClipboardData(
              stockConcentration,
              desiredVolume,
              targetRowStates,
            );
            await navigator.clipboard.writeText(clipboardData);
            snackbar.showSuccess('Copied to clipboard');
          }}
          data-heap-tracking="standalone-tools-stock-dilution-calculator-copy-to-clipboard"
        >
          copy to clipboard
        </Button>
        <Button
          variant="primary"
          color="primary"
          startIcon={<CloudDownloadOutlinedIcon />}
          onClick={handleOnDownload}
          data-heap-tracking="standalone-tools-stock-dilution-calculator-download-csv"
        >
          download csv
        </Button>
      </ButtonDiv>
    </>
  );
}

const CopyTypography = styled(Typography)({
  whiteSpace: 'pre-line',
  wordWrap: 'break-word',
  gridColumn: '1 / 3',
});

const InputsDiv = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: theme.spacing(5),
  padding: theme.spacing(6),
}));

const ResultsDiv = styled('div')({
  display: 'grid',
  gridTemplateColumns: '2fr 20px 0.75fr 20px 0.75fr 35px',
  gap: '5px',
  justifyContent: 'center',
  alignItems: 'center',
  justifyItems: 'center',
});

const ResultsBox = styled(Box)(({ theme }) => ({
  backgroundColor: Colors.GREY_10,
  padding: theme.spacing(6),
}));

const ResultHeader = styled(Typography)({
  color: Colors.TEXT_PRIMARY,
  textTransform: 'uppercase',
  gridColumn: 'span 2',
  justifySelf: 'start',
});

const AddTargetButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(5),
}));

const ButtonDiv = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '200px 180px',
  columnGap: '12px',
  padding: theme.spacing(6),
}));

export function formatClipboardData(
  stockConcentration: string,
  desiredVolume: string,
  targetStatesData: {
    componentId: string;
    targetConcentration: string;
    stockVolume: string;
    diluentVolume: string;
    errorState: string;
  }[],
): string {
  return targetStatesData
    .map(
      row =>
        `Stock Concentration - ${stockConcentration}, Desired Volume - ${desiredVolume}, Target Concentration - ${row.targetConcentration}, Stock Volume - ${row.stockVolume}, Diluent Volume - ${row.diluentVolume}`,
    )
    .join('\n');
}

export function createStructuredCSV(
  stockConcentration: string,
  desiredVolume: string,
  targetStatesData: {
    componentId: string;
    targetConcentration: string;
    stockVolume: string;
    diluentVolume: string;
    errorState: string;
  }[],
): string {
  const csvHeaderValues = [
    'Stock Concentration',
    'Desired Volume',
    'Target Concentration',
    'Stock Volume',
    'Diluent Volume',
  ];

  const csvHeader = csvHeaderValues.join(',');

  const csvData = targetStatesData
    .map(targetState =>
      [
        stockConcentration,
        desiredVolume,
        targetState.targetConcentration,
        targetState.stockVolume,
        targetState.diluentVolume,
      ].join(','),
    )
    .join('\n');

  const csvFile = `${csvHeader}\n${csvData}`;
  return csvFile;
}
