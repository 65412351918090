import React, { PropsWithChildren } from 'react';

import { styled } from '@mui/material/styles';

import PlatformHeader from 'client/app/components/nav/PlatformHeader';
import LinearProgress from 'common/ui/components/LinearProgress';

export default function Screen({ children }: PropsWithChildren<{}>) {
  return (
    <ScreenContainer>
      <PlatformHeader />
      <ScreenContent>{children}</ScreenContent>
    </ScreenContainer>
  );
}

export function LoadingScreenWithTopMainNav({ children }: PropsWithChildren<{}>) {
  return (
    <Screen>
      <LinearProgress />
      {children}
    </Screen>
  );
}

const ScreenContainer = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  backgroundColor: theme.palette.background.default,
}));

const ScreenContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  height: 0,
  flex: 1,
  zIndex: 1,
});
