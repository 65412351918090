import React from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { StandaloneTool } from 'client/app/components/nav/StandaloneToolsDialog';
import stopPropagation from 'common/lib/stopPropagation';
import Colors from 'common/ui/Colors';
import { TOP_NAV_HEIGHT } from 'common/ui/components/TopNav/topNavStyles';

type Props = {
  open: boolean;
  onClose: (tool: StandaloneTool) => void;
};

export default function StandaloneToolsMenu({ onClose, open }: Props) {
  return (
    <Container onClick={stopPropagation} hidden={!open}>
      <ToolItem
        name="Stock dilution calculator"
        description="Use this calculator to define multiple concentrations of diluted stock solutions that you want to create."
        imagePath="/app/assets/images/tools/stock-dilution.avif"
        tool={StandaloneTool.STOCK_DILUTION}
        trackingId="stock-dilution"
        onClick={onClose}
      />

      <ToolItem
        name="Absorbance / transmittance converter"
        description="This converter uses elements of the Beer-Lambert law to convert absorbance measurements to transmittance & vice-versa."
        imagePath="/app/assets/images/tools/absorbance-transmittance.avif"
        tool={StandaloneTool.ABS_TRANS_CONVERTER}
        trackingId="absorbance-transmittance"
        onClick={onClose}
      />

      <ToolItem
        name="Mass / molarity calculator"
        description="This tool is useful as a precursor step in an augmented version of the stock dilution calculator."
        imagePath="/app/assets/images/tools/mass-molarity.avif"
        tool={StandaloneTool.MASS_MOLARITY}
        trackingId="mass-molarity"
        onClick={onClose}
      />

      <ToolItem
        name="Density calculator"
        description="The convertor allows a scientist to enter the mass of an object and its volume
        separately, converting the result to a density measurement."
        imagePath="/app/assets/images/tools/density.avif"
        tool={StandaloneTool.DENSITY}
        trackingId="density-calculator"
        onClick={onClose}
      />
    </Container>
  );
}

function ToolItem({
  name,
  description,
  tool,
  imagePath,
  onClick,
  trackingId,
}: {
  name: string;
  description: string;
  tool: StandaloneTool;
  imagePath: string;
  onClick: (tool: StandaloneTool) => void;
  trackingId: string;
}) {
  return (
    <>
      <CalculatorButton
        style={{ '--bg': `url(${imagePath})` }}
        onClick={() => onClick(tool)}
        data-heap-tracking={`standalone-tools-list-${trackingId}`}
      >
        {name}
      </CalculatorButton>
      <Typography variant="body2">{description}</Typography>
    </>
  );
}

const Container = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: TOP_NAV_HEIGHT,
  padding: theme.spacing(8),
  background: Colors.WHITE,
  boxShadow: `0 4.5px 34px rgba(0,0,0,.25)`,
  color: theme.palette.text.primary,
  borderRadius: theme.spacing(0, 0, 5, 5),
  zIndex: 3,
  display: 'grid',
  gridTemplateRows: 'auto auto',
  gridTemplateColumns: 'repeat(4, minmax(150px, 300px))',
  gridAutoFlow: 'column',
  justifyContent: 'center',
  columnGap: theme.spacing(9),
  rowGap: theme.spacing(6),
  transition: 'all 250ms ease',
  transitionBehavior: 'allow-discrete',
  opacity: 1,
  '@starting-style': {
    transform: 'translateY(-100%)',
    opacity: 0,
  },
  '&[hidden]': {
    transform: 'translateY(-100%)',
    display: 'none',
    opacity: 0,
  },
}));

const CalculatorButton = styled('button')(({ theme }) => ({
  aspectRatio: '8 / 5',
  position: 'relative',
  border: 0,
  outline: `1px solid rgba(0,0,0,.25)`,
  outlineOffset: -1,
  borderRadius: theme.spacing(3),
  display: 'grid',
  alignContent: 'end',
  justifyContent: 'start',
  padding: theme.spacing(5, 8, 5, 5),
  fontFamily: 'inter',
  fontSize: '20px',
  fontWeight: 900,
  textAlign: 'left',
  cursor: 'pointer',
  overflow: 'hidden',
  background: 'transparent',
  transition: 'all 0.1s ease-in-out',
  color: theme.palette.text.primary,

  '&::before': {
    position: 'absolute',
    inset: 0,
    content: '""',
    pointerEvents: 'none',
    transformOrigin: 'center',
    transition: 'transform .4s ease-in-out;',
    zIndex: -1,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'var(--bg)',
  },
  '&:hover': {
    outlineColor: theme.palette.primary.main,
    outlineWidth: 2,
    color: theme.palette.primary.main,
    '&::before': {
      transform: 'scale(1.05)',
    },
  },
}));
