import { parseMeasurement } from 'common/lib/format';
import { Measurement } from 'common/types/mix';

type Unit = {
  // how the unit should be displayed, e.g. 'ul'
  label: string;
  // for rate like units (e.g. s/l), captures the numerator and denominator
  // unit label. This is useful for unit conversions
  rate?: { numerator: string; denominator: string };
  // value to scale measurements in this unit to reach some common unit
  coefficient: number;
};

type Dimension = {
  name: string;
  knownUnits: Unit[];
};

const SIPrefixes = [
  { prefix: 'y', value: 1e-24 },
  { prefix: 'z', value: 1e-21 },
  { prefix: 'a', value: 1e-18 },
  { prefix: 'f', value: 1e-15 },
  { prefix: 'p', value: 1e-12 },
  { prefix: 'n', value: 1e-9 },
  { prefix: 'u', value: 1e-6 },
  { prefix: 'm', value: 1e-3 },
  { prefix: 'c', value: 1e-2 },
  { prefix: 'd', value: 1e-1 },
  { prefix: 'da', value: 1e1 },
  { prefix: '', value: 1 },
  { prefix: 'h', value: 1e2 },
  { prefix: 'k', value: 1e3 },
  { prefix: 'M', value: 1e6 },
  { prefix: 'G', value: 1e9 },
  { prefix: 'T', value: 1e12 },
  { prefix: 'P', value: 1e15 },
  { prefix: 'E', value: 1e18 },
  { prefix: 'Z', value: 1e21 },
  { prefix: 'Y', value: 1e24 },
];

function getSIUnits(symbol: string, factor = 1): Unit[] {
  return SIPrefixes.map(({ prefix, value }) => ({
    label: `${prefix}${symbol}`,
    coefficient: value * factor,
  }));
}

function getSIRates(
  numerator: Unit[] | string,
  denominator: Unit[] | string,
  factor = 1,
): Unit[] {
  const numeratorUnits = Array.isArray(numerator) ? numerator : getSIUnits(numerator);
  const denominatorUnits = Array.isArray(denominator)
    ? denominator
    : getSIUnits(denominator);

  return numeratorUnits.flatMap(num =>
    denominatorUnits.map(den => ({
      label: `${num.label}/${den.label}`,
      rate: { numerator: num.label, denominator: den.label },
      coefficient: (num.coefficient * factor) / den.coefficient,
    })),
  );
}

export class UnitLibrary {
  dimensions: Map<string, Dimension>;
  units: Map<string, Unit>;

  constructor() {
    const dimensions: Dimension[] = [
      {
        name: 'volume',
        knownUnits: getSIUnits('l'),
      },
      {
        name: 'mass',
        knownUnits: getSIUnits('g', 1e-3),
      },
      {
        name: 'molar concentration',
        knownUnits: getSIRates('Mol', 'l'),
      },
      {
        name: 'mass concentration',
        knownUnits: getSIRates('g', 'l', 1e-3),
      },
      {
        name: 'relative concentration',
        knownUnits: [
          {
            label: 'X',
            coefficient: 1.0,
          },
        ],
      },
      {
        name: 'volume concentration',
        knownUnits: [
          {
            label: 'v/v',
            coefficient: 1.0,
          },
        ],
      },
      {
        name: 'unit concentration',
        knownUnits: getSIRates([{ label: 'U', coefficient: 1 }], 'l'),
      },
      {
        name: 'cell concentration',
        knownUnits: getSIRates([{ label: 'cells', coefficient: 1e-12 }], 'l'),
      },
      {
        name: 'flow rate',
        knownUnits: getSIRates('l', 's'),
      },
      {
        name: 'time',
        knownUnits: getSIUnits('s'),
      },
      {
        name: 'time',
        knownUnits: [
          {
            label: 'min',
            coefficient: 60.0,
          },
        ],
      },
      {
        name: 'time',
        knownUnits: [
          {
            label: 'h',
            coefficient: 3600.0,
          },
        ],
      },
    ];

    this.dimensions = dimensions.reduce((m, dimension) => {
      dimension.knownUnits.reduce((m, unit) => {
        m.set(unit.label, dimension);
        return m;
      }, m);
      return m;
    }, new Map<string, Dimension>());

    this.units = dimensions.reduce((m, dimension) => {
      dimension.knownUnits.reduce((m, unit) => {
        m.set(unit.label, unit);
        return m;
      }, m);
      return m;
    }, new Map<string, Unit>());
  }
}

const _unitLibrary = new UnitLibrary();

export function getUnit(unitLabel: string): Unit {
  const unit = _unitLibrary.units.get(unitLabel);

  if (!unit) {
    throw new Error(`unknown unit: ${unitLabel}`);
  }
  return unit;
}

export function getDimensionName(unit: string): string {
  return _unitLibrary.dimensions.get(unit)?.name || 'unknown unit';
}

export function isNone(a: Measurement): boolean {
  return a.value === 0.0 && a.unit === '';
}

/**
 * Returns true if it's possible to perform comparison and arithmetic between
 * the two units
 */
export function isCompatible(aUnitLabel: string, bUnitLabel: string): boolean {
  if (!aUnitLabel || !bUnitLabel) {
    // it's always legal to do comparison against zero
    return true;
  }
  const aName = getDimensionName(aUnitLabel);
  const bName = getDimensionName(bUnitLabel);
  return aName === bName;
}

/**
 * throws an exception if a and b are not compatible
 */
export function assertCompatible(a: string, b: string) {
  if (!a || !b) {
    // it's always legal to do comparison against zero
    return;
  }
  const aName = getDimensionName(a);
  const bName = getDimensionName(b);
  if (aName !== bName) {
    throw new Error(
      `assertion failed: ${aName} unit (${a}) is not compatible with ${bName} unit (${b})`,
    );
  }
}

function comparable(a: Measurement): number {
  const unit = _unitLibrary.units.get(a.unit);
  if (unit) {
    return a.value * unit.coefficient;
  }
  throw new Error(`unknown unit ${a.unit}`);
}

/**
 * returns true if a == b, to within a given tolerance.
 * tol is given as a proportion of a or b, whichever has the largest absolute
 * value
 * throws an exception if a and b are not compatible
 */
export function equals(a: Measurement, b: Measurement, tol = 1e-6): boolean {
  assertCompatible(a.unit, b.unit);
  if (isNone(a) || isNone(b)) {
    return false;
  }
  const cA = comparable(a);
  const cB = comparable(b);
  const tolerance = Math.max(Math.abs(cA), Math.abs(cB)) * tol;
  return Math.abs(cA - cB) < tolerance;
}

/**
 * returns true if a < b.
 * throws an exception if a and b are not compatible
 */
export function lessThan(a: Measurement, b: Measurement): boolean {
  assertCompatible(a.unit, b.unit);
  if (isNone(a) || isNone(b)) {
    return false;
  }
  return comparable(a) < comparable(b);
}

/**
 * returns true if a <= b.
 * throws an exception if a and b are not compatible
 */
export function lessThanEqual(a: Measurement, b: Measurement): boolean {
  assertCompatible(a.unit, b.unit);
  if (isNone(a) || isNone(b)) {
    return false;
  }
  return comparable(a) <= comparable(b);
}

/**
 * returns true if a > b.
 * throws an exception if a and b are not compatible
 */
export function greaterThan(a: Measurement, b: Measurement): boolean {
  assertCompatible(a.unit, b.unit);
  if (isNone(a) || isNone(b)) {
    return false;
  }
  return comparable(a) > comparable(b);
}

/**
 * returns true if a >= b.
 * throws an exception if a and b are not compatible
 */
export function greaterThanEqual(a: Measurement, b: Measurement): boolean {
  assertCompatible(a.unit, b.unit);
  if (isNone(a) || isNone(b)) {
    return false;
  }
  return comparable(a) >= comparable(b);
}

/**
 * returns a / b
 * throws an exception if a and b are not compatible
 */
export function divide(a: Measurement, b: Measurement): number {
  assertCompatible(a.unit, b.unit);
  if (isNone(a)) {
    return 0.0;
  }
  if (isNone(b)) {
    return Math.sign(a.value) * Infinity;
  }
  return comparable(a) / comparable(b);
}

/**
 * @param measurement `{initial: time (s),  rate: flow rate (l/s), inUnit: volume (l)}`
 * @remarks conversion supported for any SI prefixed unit
 */
export const timeToVolume = rateMultiplicationConverter({
  initial: getUnit('s'),
  rate: getUnit('l/s'),
  target: getUnit('l'),
});

/**
 * @param measurement `{initial: volume (l),  rate: flow rate (l/s), inUnit: time (s)}`
 * @remarks conversion supported for any SI prefixed unit
 */
export const volumeToTime = rateDivisionConverter({
  initial: getUnit('l'),
  rate: getUnit('l/s'),
  target: getUnit('s'),
});

/**
 * @param measurement `{initial: volume (l),  rate: concentration (g/l), inUnit: mass (g)}`
 * @remarks conversion supported for any SI prefixed unit
 */
export const volumeToMass = rateMultiplicationConverter({
  initial: getUnit('l'),
  rate: getUnit('g/l'),
  target: getUnit('g'),
});

/**
 * @param measurement `{initial: mass (g),  rate: concentration (g/l), inUnit: volume (l)}`
 * @remarks conversion supported for any SI prefixed unit
 */
export const massToVolume = rateDivisionConverter({
  initial: getUnit('g'),
  rate: getUnit('g/l'),
  target: getUnit('l'),
});

/**
 * stringRateConverter is a convenience method for working with editors that
 * store measurements as strings, but which require calculations to be performed
 * on the values
 *
 * @param strings are string measurements that should be converted to a new
 * string measurement
 * @param convert describes the valid unit conversions that `strings` can undergo
 * @returns measurement as a string if the conversion is possible
 */
export function stringRateConverter(
  strings: {
    initial?: string;
    rate?: string;
    inUnit?: string;
  },
  convert: (measurement: {
    initial?: Measurement;
    rate?: Measurement;
    inUnit?: Unit;
  }) => Measurement | undefined,
) {
  const converted = convert({
    initial: parseMeasurement(strings.initial ?? ''),
    rate: parseMeasurement(strings.rate ?? ''),
    inUnit: getUnit(strings.inUnit ?? ''),
  });
  return converted ? `${converted.value} ${converted.unit}` : undefined;
}

/**
 * @param units that obeys `target` = `initial` * `rate` unit conversions.
 * Otherwise throws an error.
 *
 * @example
 *  - valid: `{ initial: "l", rate: "g/l", target: "mg" }`
 *  - invalid: `{ initial: "g", rate: "g/l", target: "ul" }`
 *
 * @returns a function which may return undefined if 0 division or measurements
 * are not compatible with the provided unit constraints
 */
function rateMultiplicationConverter(units: { initial: Unit; rate: Unit; target: Unit }) {
  if (
    !units.rate.rate ||
    !isCompatible(units.initial.label, units.rate.rate.denominator) ||
    !isCompatible(units.target.label, units.rate.rate.numerator)
  ) {
    throw new Error(
      `cannot convert ${units.initial.label} to ${units.target.label} using ${units.rate.label} by multiplication of units`,
    );
  }
  return (measurement: {
    initial?: Measurement;
    rate?: Measurement;
    inUnit?: Unit;
  }): Measurement | undefined => {
    if (!measurement.inUnit) {
      measurement.inUnit = units.target;
    }
    if (
      !measurement.initial ||
      !measurement.rate ||
      !isCompatible(units.initial.label, measurement.initial.unit) ||
      !isCompatible(units.rate.label, measurement.rate.unit) ||
      !isCompatible(units.target.label, measurement.inUnit.label)
    ) {
      return;
    }

    const iUnit = getUnit(measurement.initial.unit);
    const rUnit = getUnit(measurement.rate.unit);
    const value =
      (measurement.initial.value *
        iUnit.coefficient *
        measurement.rate.value *
        rUnit.coefficient) /
      measurement.inUnit.coefficient;

    return { value, unit: measurement.inUnit.label };
  };
}

/**
 * @param units that obeys `target` = `initial` / `rate` unit conversions.
 * Otherwise throws an error.
 *
 * @example
 *  - valid: `{ initial: "g", rate: "g/l", target: "ul" }`
 *  - invalid: `{ initial: "l", rate: "g/l", target: "mg" }`
 *
 * @returns a function which may return undefined if 0 division or measurements
 * are not compatible with the provided unit constraints
 */
function rateDivisionConverter(units: { initial: Unit; rate: Unit; target: Unit }) {
  if (
    !units.rate.rate ||
    !isCompatible(units.initial.label, units.rate.rate.numerator) ||
    !isCompatible(units.target.label, units.rate.rate.denominator)
  ) {
    throw new Error(
      `cannot convert ${units.initial.label} to ${units.target.label} using ${units.rate.label} by division of units`,
    );
  }
  return (measurement: {
    initial?: Measurement;
    rate?: Measurement;
    inUnit?: Unit;
  }): Measurement | undefined => {
    if (!measurement.inUnit) {
      measurement.inUnit = units.target;
    }
    if (
      !measurement.initial ||
      !measurement.rate ||
      !measurement.rate.value ||
      !isCompatible(units.initial.label, measurement.initial.unit) ||
      !isCompatible(units.rate.label, measurement.rate.unit) ||
      !isCompatible(units.target.label, measurement.inUnit.label)
    ) {
      return;
    }

    const iUnit = getUnit(measurement.initial.unit);
    const rUnit = getUnit(measurement.rate.unit);
    const value =
      (measurement.initial.value * iUnit.coefficient) /
      (measurement.rate.value * rUnit.coefficient) /
      measurement.inUnit.coefficient;

    return { value, unit: measurement.inUnit.label };
  };
}
