import React from 'react';

import Fab from '@mui/material/Fab';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Colors from 'common/ui/Colors';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';
import { TemplateWorkflowIcon } from 'common/ui/icons/TemplateWorkflow';

type Props = {
  protocolName: string;
  handleOnSimulate: () => void;
};

export const EditProtocolInstanceHeader = ({ handleOnSimulate, protocolName }: Props) => {
  return (
    <Wrapper>
      <StyledTemplateWorkflowIcon />
      <TypographyWrapper>
        <ProtocolHeader variant="h4">{protocolName}</ProtocolHeader>
        <Typography variant="body2">Modify the inputs of your protocol</Typography>
      </TypographyWrapper>
      <SimulateButton
        onClick={handleOnSimulate}
        color="primary"
        size="medium"
        variant="extended"
      >
        Simulate protocol
      </SimulateButton>
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'start',
  alignItems: 'center',
  gap: theme.spacing(4),
  borderBottom: `1px solid ${Colors.GREY_30}`,
  padding: theme.spacing(4, 6),
}));

const TypographyWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const SimulateButton = styled(Fab)(() => ({
  marginLeft: 'auto',
}));

const ProtocolHeader = styled(TypographyWithTooltip)(() => ({
  fontWeight: 600,
}));

const StyledTemplateWorkflowIcon = styled(TemplateWorkflowIcon)(() => ({
  color: '#1773C9',
}));
