import React, { ReactNode, useContext } from 'react';

import { useQuery } from '@apollo/client';
import { styled } from '@mui/material/styles';

import { QUERY_SIMULATION } from 'client/app/api/gql/queries';
import { useDOEDesign, useMixPreview } from 'client/app/api/MixPreviewApi';
import DesignScreen from 'client/app/apps/simulation-details/DesignScreen';
import InstructionsScreen from 'client/app/apps/simulation-details/instructions/InstructionsScreen';
import Navigation from 'client/app/apps/simulation-details/Navigation';
import ResourcesScreen from 'client/app/apps/simulation-details/overview/ResourcesScreen';
import SimulationDetailsHeader from 'client/app/apps/simulation-details/SimulationDetailsHeader';
import useStageDetails from 'client/app/apps/simulation-details/useStageDetails';
import PlatePrepScreen from 'client/app/components/PlatePrep/PlatePrepScreen';
import UIErrorBox from 'client/app/components/UIErrorBox';
import { Simulation, SimulationOrExecutionStatusesEnum } from 'client/app/gql';
import usePlateTypes from 'client/app/hooks/usePlateTypes';
import { executionRoutes, simulationRoutes } from 'client/app/lib/nav/actions';
import { ScreenRegistry } from 'client/app/registry';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { getErrorMessage } from 'common/lib/graphQLErrors';
import LinearProgress from 'common/ui/components/LinearProgress';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import MixScreen from 'common/ui/components/simulation-details/mix/MixScreen';
import { ScreenIds } from 'common/ui/components/simulation-details/simulationDetailsScreenIds';
import {
  RouteHiddenContext,
  RouteHiddenContextProvider,
} from 'common/ui/lib/router/RouteHiddenContext';

type Props = {
  simulationId: SimulationId;
  sourceScreenId: string;
  subscreenId: ScreenIds;
};

/**
 * Main screen for simulation details. All subscreens are rendered (even if not
 * the current route) so that each screen remains unchanged when the user
 * switches between tabs.
 */
function SimulationDetails(props: Props) {
  const { simulationId, sourceScreenId, subscreenId } = props;

  const {
    loading: isSimulationLoading,
    data,
    error,
  } = useQuery(QUERY_SIMULATION, {
    variables: { id: simulationId, withTasks: true, withVisualisations: true },
  });

  const isPlaybackEnabled = useFeatureToggle('SIMULATION_PLAYBACK');
  const isShowErrorPreviewEnabled = useFeatureToggle('SHOW_ERROR_PREVIEW');
  const isNewDOEEnabled = useFeatureToggle('NEW_DOE');
  const isHighLevelInstructionsEnabled = useFeatureToggle('HIGH_LEVEL_INSTRUCTIONS');

  const simulation = data?.simulation as Simulation;
  const { status: fetchPreviewStatus, mixPreview } = useMixPreview(simulation);
  const design = useDOEDesign(simulation);
  const stageDetails = useStageDetails(simulation);

  const plateTypes = usePlateTypes();

  const { navigate } = useNavigation();

  if (isSimulationLoading || (!!simulation && fetchPreviewStatus === 'loading')) {
    return <LinearProgress />;
  }
  if (error) {
    return <UIErrorBox>{getErrorMessage(error)}</UIErrorBox>;
  }

  if (!simulation) {
    return null;
  }

  const isRoutedFromExecutionDetailsURL =
    sourceScreenId === ScreenRegistry.EXECUTION_DETAILS;

  const simulationFailed =
    simulation.transitiveStatus === SimulationOrExecutionStatusesEnum.SIMULATION_FAILED;
  // if the simulation failed, we still try to fetch the preview, but there might not be one
  // (antha-core only produces previews for some error types), so don't show an
  // error if the fetch fails for failed simulations
  if (fetchPreviewStatus === 'error' && !simulationFailed) {
    // The Header will show a schedule button; but how can the user be confider they want to schedule if they cannot see the details?
    // TODO: When redesigning the simulation details, we should revisit what scenarios we want to show the Schedule button.
    return (
      <>
        <SimulationDetailsHeader simulation={simulation} />
        <UIErrorBox>
          Sorry. We could not retrieve the proper information needed for this simulation.
          Please contact our Support team for assistance.
        </UIErrorBox>
      </>
    );
  }

  const handleNavigationButtonClick = (subscreenId: ScreenIds) => {
    if (simulation.execution && isRoutedFromExecutionDetailsURL) {
      navigate(
        executionRoutes.executionDetailsSubscreen,
        { executionId: simulation.execution.id, subscreenId },
        { keepURLSearchParams: true },
      );
    } else {
      navigate(
        simulationRoutes.simulationDetailsSubscreen,
        {
          simulationId: simulation.id,
          subscreenId,
        },
        { keepURLSearchParams: true },
      );
    }
  };

  const isPreviewAvailable = !!mixPreview;
  // the setup screen isn't helpful for failed simulations, so never show it
  const showSetup = isPreviewAvailable && !simulationFailed;
  const showPreview =
    (isPreviewAvailable && !simulationFailed) || isShowErrorPreviewEnabled;

  const showDesign =
    isNewDOEEnabled && !!design && simulation.simulationSeriesPart != null;
  const showInstructions = isHighLevelInstructionsEnabled && !!mixPreview?.instructions;

  return (
    <Container>
      <SimulationDetailsHeader simulation={simulation} />
      <Navigation
        currentScreenId={subscreenId}
        screenIds={getSubscreenIds(showPreview, showSetup, showDesign, showInstructions)}
        onSubscreenButtonClick={handleNavigationButtonClick}
      />
      <HideableScreen
        show={subscreenId === ScreenIds.RESOURCES}
        tabTitle={`Simulation Resources: ${simulation.name}`}
      >
        <ResourcesScreen
          simulation={simulation}
          deck={mixPreview?.deck}
          plateTypes={plateTypes}
        />
      </HideableScreen>
      {mixPreview && showSetup && (
        <HideableScreen
          show={subscreenId === ScreenIds.SETUP}
          tabTitle={`Simulation Plate Setup: ${simulation.name}`}
        >
          <PlatePrepScreen
            mixPreview={mixPreview}
            simulationName={simulation.name}
            showPrintButton
            padding={9}
          />
        </HideableScreen>
      )}
      {showDesign && (
        <HideableScreen
          show={subscreenId === ScreenIds.DESIGN}
          tabTitle={`Simulation Design: ${simulation.name}`}
        >
          <DesignScreen
            design={design}
            part={
              simulation?.simulationSeriesSiblings?.length
                ? simulation.simulationSeriesPart
                : null
            }
            simulationName={simulation.name}
          />
        </HideableScreen>
      )}
      {showInstructions && mixPreview.instructions && (
        <HideableScreen
          show={subscreenId === ScreenIds.INSTRUCTIONS}
          tabTitle={`High Level Instructions: ${simulation.name}`}
        >
          <InstructionsScreen
            simulation={simulation}
            instructions={mixPreview.instructions}
          />
        </HideableScreen>
      )}
      {mixPreview && showPreview && (
        <HideableScreen
          show={subscreenId === ScreenIds.PREVIEW}
          tabTitle={`Simulation Preview: ${simulation.name}`}
        >
          <MixScreen
            mixPreview={mixPreview}
            plateTypes={plateTypes}
            isPlaybackEnabled={isPlaybackEnabled}
            design={design}
            stageDetails={stageDetails}
          />
        </HideableScreen>
      )}
    </Container>
  );
}

/**
 * Return the subscreens to show in the top navigation bar.
 * Each subscreen has one button in the navigation bar.
 */
function getSubscreenIds(
  showPreview: boolean,
  showSetup: boolean,
  showDesign: boolean,
  showInstructions: boolean,
): readonly ScreenIds[] {
  // The overview screen is always shown
  const subscreens = [ScreenIds.RESOURCES];
  if (showSetup) {
    subscreens.push(ScreenIds.SETUP);
  }
  if (showPreview) {
    subscreens.push(ScreenIds.PREVIEW);
  }
  if (showDesign) {
    subscreens.push(ScreenIds.DESIGN);
  }
  if (showInstructions) {
    subscreens.push(ScreenIds.INSTRUCTIONS);
  }
  return subscreens;
}

type HidableScreenProps = {
  show: boolean;
  children: ReactNode;
  tabTitle: string;
};

/**
 * Always render content, but only make it visible when show=true. CSS `display`
 * is used to show/hide the content. This is useful because users expect each
 * screen's contents to persist when switching tabs in execution preview.
 *
 * Child components can access the RouteHiddenContext to determine if the parent
 * route is hidden.
 */
function HideableScreen({ show, children, tabTitle }: HidableScreenProps) {
  // We might already be in a RouteHiddenContext from a parent route. If the
  // parent route is hidden, then the context defined here should also be
  // hidden.
  const hiddenContext = useContext(RouteHiddenContext);
  return (
    <div style={{ display: show ? 'contents' : 'none' }}>
      <RouteHiddenContextProvider
        hidden={hiddenContext.hidden || !show}
        tabTitle={tabTitle}
      >
        {children}
      </RouteHiddenContextProvider>
    </div>
  );
}

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  // Fill remaining space by setting height: 0 and flex: 1
  // https://stackoverflow.com/a/14964944
  height: 0,
  flex: 1,
});

export default SimulationDetails;
