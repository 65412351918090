import produce, { Draft } from 'immer';

import { DeviceParsedRunConfigQuery } from 'client/app/gql';
import {
  ELEMENT_INSTANCE_WIDTH,
  STAGE_PADDING,
} from 'client/app/lib/layout/LayoutHelper';
import { State } from 'client/app/state/WorkflowBuilderStateContext';
import { ElementInstance, LayoutPreferences, Stage } from 'common/types/bundle';
import { Position2d } from 'common/types/Position';

/**
 * Performs a check to determine if the current workflow should require a device
 * to be selected or not. Primarily used for determining the `requiresDevice`
 * value when the `resetWorkflow` dispatch is performed on workflow builder load.
 */
export function workflowRequiresDevice(state: State) {
  if (state.config.global.requiresDevice !== undefined) {
    return state.config.global.requiresDevice;
  }
  const hasDevices = (state.config.configuredDevices ?? []).length > 0;
  const hasElements = state.elementInstances.length > 0;
  // If the workflow is an existing workflow with `requiresDevice` not set, we have to determine what
  // value to set `requiresDevice` to. We make an assumption that, if the workflow has not had any
  // devices or elements added to it already, it's probably an "empty" workflow and `requiresDevice`
  // should be set to true.
  // If the user has selected devices, or added elements, it tells us it's likely an existing/edited
  // workflow, so in that case we just return if there are devices in the workflow or not.
  if (hasDevices || hasElements) {
    return hasDevices;
  }
  return true;
}

/**
 * Converts the relevant default layout options from DeviceParsedRunConfig data into a
 * LayoutPreferences object
 */
export function formatDefaultLayoutOptions(
  config: DeviceParsedRunConfigQuery | undefined,
): LayoutPreferences {
  const defaultLayoutOptions = config?.parsedRunConfig.config.defaultLayoutOptions;
  return {
    inputs: defaultLayoutOptions?.inputs || [],
    outputs: defaultLayoutOptions?.outputs || [],
    tipwastes: defaultLayoutOptions?.tipwastes || [],
    tipboxes: defaultLayoutOptions?.tipboxes || [],
    temporaryLocations: defaultLayoutOptions?.temporaryLocations || [],
    plates: {},
  };
}

export function assignElementsToStages(
  elements: ElementInstance[],
  stages: Stage[],
  options?: { reset: boolean },
) {
  if (options?.reset) {
    stages.forEach(stage => {
      stage.elementIds = [];
    });
  }

  elements.forEach(element => {
    assignElementToStage(element, stages);
  });
}

export function assignElementToStage(element: ElementInstance, stages: Stage[]) {
  const stage = stageForElement(element, stages);

  if (stage) {
    stage.elementIds.push(element.Id);
  }
}

function elementInStagePredicate(element: ElementInstance) {
  return (stage: Stage, i: number, stages: Stage[]) => {
    const minX = i === 0 ? undefined : stage.meta.x;
    const maxX = stages[i + 1]?.meta.x;
    return (
      (minX === undefined || element.Meta.x >= minX) &&
      (maxX === undefined || element.Meta.x < maxX)
    );
  };
}

function stageIndexForElement(element: ElementInstance, stages: Stage[]) {
  return stages.findIndex(elementInStagePredicate(element));
}

function stageForElement(element: ElementInstance, stages: Stage[]) {
  return stages.find(elementInStagePredicate(element));
}

export function checkStageOverlapWithElements(
  elements: ElementInstance[],
  stages: Stage[],
): { error: false } | { error: true; stageIds: string[] } {
  const overlapping = new Set<string>();

  stages.forEach((stage, i) => {
    elements.forEach(el => {
      if (el) {
        if (
          stage.meta.x !== undefined &&
          i > 0 &&
          stage.meta.x >= el.Meta.x - STAGE_PADDING &&
          stage.meta.x <= el.Meta.x + ELEMENT_INSTANCE_WIDTH + STAGE_PADDING
        ) {
          overlapping.add(stage.id);
        }
      }
    });
  });

  return overlapping.size
    ? {
        error: true,
        stageIds: [...overlapping],
      }
    : { error: false };
}

/**
 * Check the validity of element positions while dragging.
 * We check if they are overlapping stage boundaries, or have forward
 * connections to elements in earlier stages, and return an error if so.
 */
export function checkElementDragValidity(
  elements: ElementInstance[],
  stages: Stage[],
  state: State,
):
  | { error: 'None' }
  | { error: 'Overlap'; invalidStages: string[] }
  | { error: 'Connections'; invalidIds: string[] } {
  const invalidStages = checkStageOverlapWithElements(elements, stages);

  if (invalidStages.error) {
    return {
      error: 'Overlap',
      invalidStages: invalidStages.stageIds,
    };
  }

  const invalidIds = new Set<string>();

  elements.forEach(element => {
    const stage = stageIndexForElement(element, stages);

    const invalidConnections = state.InstancesConnections.filter(conn => {
      if (conn.Target.ElementInstance === element.name) {
        const targetStage = stage;
        const sourceElement =
          elements.find(el => el.name === conn.Source.ElementInstance) ??
          state.elementInstances.find(el => el.name === conn.Source.ElementInstance);
        if (sourceElement) {
          const sourceStage = stageIndexForElement(sourceElement, stages);

          if (targetStage < sourceStage) {
            return true;
          }
        }
      } else if (conn.Source.ElementInstance === element.name) {
        const sourceStage = stage;
        const targetElement =
          elements.find(el => el.name === conn.Target.ElementInstance) ??
          state.elementInstances.find(el => el.name === conn.Target.ElementInstance);

        if (targetElement) {
          const targetStage = stageIndexForElement(targetElement, stages);

          if (targetStage < sourceStage) {
            return true;
          }
        }
      }

      return false;
    });

    if (invalidConnections.length) {
      invalidIds.add(element.Id);
      invalidConnections.forEach(conn => {
        invalidIds.add(conn.id);
      });
    }
  });

  if (invalidIds.size) {
    return { error: 'Connections', invalidIds: [...invalidIds] };
  }

  return { error: 'None' };
}

const DRAG_ERRORS = {
  ElementOverlap: 'Can’t place an element over a stage divider',
  StageOverlap: 'Can’t place a stage over an element',
  Connections:
    'Element inputs must be connected to other elements in previous or current stage',
};

/**
 * Check the position of elements and stages for validity while dragging.
 * If there are problems such as elements overlapping with stage boundaries,
 * or with forward connections to elements in earlier stages, then we return
 * an error identifying which elements or stages were problematic.
 */
export function checkDragValidity(
  state: Draft<State>,
  elements: ElementInstance[] | undefined,
  stages: Stage[] | undefined,
) {
  state.dragError = undefined;
  state.erroredObjectIds = [];
  state.stages.forEach(stage => {
    stage.meta.showAsInvalid = false;
  });

  const validity = checkElementDragValidity(
    elements ?? state.elementInstances,
    stages ?? state.stages,
    state,
  );

  switch (validity.error) {
    case 'Overlap':
      state.stages.forEach(stage => {
        stage.meta.showAsInvalid = validity.invalidStages.includes(stage.id);
      });
      state.dragError =
        elements === undefined ? DRAG_ERRORS.StageOverlap : DRAG_ERRORS.ElementOverlap;
      break;
    case 'Connections':
      state.erroredObjectIds = validity.invalidIds;
      state.dragError = DRAG_ERRORS.Connections;
      break;
  }
}

export function setDragDelta(
  draft: Draft<State>,
  draggedObjectId: string,
  delta: Position2d,
) {
  draft.dragDelta = delta;
  draft.draggedObjectId = draggedObjectId;

  const dragDelta = draft.dragDelta;

  if (dragDelta) {
    const movedElements = produce(
      draft.elementInstances.filter(ei => draft.selectedObjectIds.includes(ei.Id)),
      eis => {
        eis.forEach(ei => {
          ei.Meta.x += dragDelta.x;
          ei.Meta.y += dragDelta.y;
        });
      },
    );

    draft.dragError = undefined;
    draft.erroredObjectIds = [];
    draft.stages.forEach(stage => {
      stage.meta.showAsInvalid = false;
    });

    checkDragValidity(draft, movedElements, undefined);
  }
}

export function resetDragState(draft: Draft<State>) {
  draft.dragDelta = null;
  draft.draggedObjectId = null;
  draft.erroredObjectIds = [];
  draft.dragError = undefined;
  draft.stageDragDelta = 0;
  draft.lastNudged = undefined;

  draft.stages.forEach(stage => {
    stage.meta.showAsInvalid = false;
  });
}

export function getElementFromSelection(
  draft: Draft<State>,
): ElementInstance | undefined {
  return draft.elementInstances.find(ei => ei.Id === draft.selectedObjectIds[0]);
}

export function isNewAddedElement(elementInstance: ElementInstance | undefined): boolean {
  if (!elementInstance) return false;

  const { status, dirty } = elementInstance.Meta;

  return !dirty && (!status || status === 'neutral');
}
