import React, { useState } from 'react';

import { styled } from '@mui/material/styles';

import {
  MessageType,
  NoEntitiesMessage,
} from 'client/app/apps/experiments/NoEntitiesMessage';
import PolicyCard from 'client/app/apps/policy-library/PolicyCard';
import Policies, { Policy } from 'client/app/lib/policies';
import Colors from 'common/ui/Colors';
import CardGrid from 'common/ui/components/CardGrid';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';
import SearchField from 'common/ui/components/SearchField';

type Props = {
  onPick?: (policy: string) => void;
  onQuickPick?: (policy: string) => void;
  selectedId?: string;
};

export default function PolicyLibraryApp(props: Props) {
  const { onPick, selectedId, onQuickPick } = props;
  const [filterQuery, setFilterQuery] = useState('');

  const filteredItems = computeFilteredPolicies(Policies, filterQuery);
  const renderedItems = filteredItems.map((item, index) => (
    <PolicyCard
      key={index.toString()}
      onPick={onPick}
      onQuickPick={onQuickPick}
      policy={item}
      selected={selectedId === item.id}
    />
  ));

  return (
    <Container>
      <ContainerWithIntersectionBar
        headerRightContent={
          <SearchField onQueryChange={setFilterQuery} placeholder="Search" addMargin />
        }
        content={
          <CardGrid>
            {filterQuery !== '' && renderedItems.length === 0 && (
              <NoEntitiesMessage
                entityName="liquid policies"
                messageType={MessageType.NO_FILTER_RESULTS}
                searchQuery={filterQuery}
              />
            )}
            {renderedItems}
          </CardGrid>
        }
      />
    </Container>
  );
}

function computeFilteredPolicies(policiesArray: Policy[], filterQuery: string): Policy[] {
  const lowerCaseQuery = filterQuery.toLowerCase();

  return policiesArray.filter(policy => {
    const { name, description, id } = policy;
    return (
      name.toLowerCase().includes(lowerCaseQuery) ||
      description.toLowerCase().includes(lowerCaseQuery) ||
      id.toLowerCase().includes(lowerCaseQuery)
    );
  });
}

const Container = styled('div')({
  background: Colors.GREY_5,
  flex: 1,
  overflow: 'hidden',
});
