import React, { useCallback, useContext } from 'react';

import Typography from '@mui/material/Typography';

import FilterChip, {
  FilterChipWithPopoverProps,
} from 'common/ui/components/FilterChip/FilterChip';
import { FilterChipContext } from 'common/ui/components/FilterChip/FilterChipContext';
import Switch from 'common/ui/components/Switch';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = Omit<FilterChipWithPopoverProps<boolean>, 'defaultChipLabel'> & {
  activeChipLabel: string;
  inactiveChipLabel: string;
};

/**
 * A FilterChip that has a Switch (boolean) as its popover content.
 */
export default React.memo(function FilterChipWithSwitch(props: Props) {
  const { filterValue, activeChipLabel, inactiveChipLabel, onFilter, size } = props;

  const valueLabel = filterValue ? activeChipLabel : inactiveChipLabel;

  // What happens when you clear the chip.
  const handleDelete = useCallback(() => {
    onFilter(false);
  }, [onFilter]);

  return (
    <FilterChip
      heading={props.heading}
      chipLabel={valueLabel}
      filterValue={filterValue}
      className={props.className}
      onDelete={handleDelete}
      isActive={filterValue}
      isDisabled={props.isDisabled}
      size={size}
      popoverContent={
        <PopoverContent
          filterValue={filterValue}
          activeChipLabel={activeChipLabel}
          onFilter={onFilter}
        />
      }
    />
  );
});

type PopoverProps = Pick<Props, 'activeChipLabel' | 'onFilter' | 'filterValue'>;

const PopoverContent = ({ onFilter, activeChipLabel, filterValue }: PopoverProps) => {
  const classes = useStyles();
  const { onClose } = useContext(FilterChipContext);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onFilter(e.target.checked);
    onClose();
  };
  return (
    <div className={classes.options}>
      <Switch onChange={onChange} color="primary" checked={filterValue} />
      <Typography variant="body1"> {activeChipLabel} </Typography>
    </div>
  );
};

const useStyles = makeStylesHook(theme => ({
  options: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '276px',
    paddingTop: theme.spacing(3),
  },
}));
