import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import IconFullscreen from '@mui/icons-material/Fullscreen';
import IconFullscreenExit from '@mui/icons-material/FullscreenExit';
import Search from '@mui/icons-material/Search';
import IconButton, { iconButtonClasses } from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import clamp from 'lodash/clamp';

import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { DOEDesign, WellLocationOnDeckItem } from 'common/types/mix';
import { MixPreview, StageDetails } from 'common/types/mixPreview';
import { SIMULATION_DETAILS_PREVIEW_TAB_ID } from 'common/ui/AnalyticsConstants';
import Colors from 'common/ui/Colors';
import { useDialogManager } from 'common/ui/components/DialogManager';
import InfoDialog from 'common/ui/components/InfoDialog';
import { PlateNameData } from 'common/ui/components/simulation-details/data-utils/PlatesDataUtils';
import computeSelectedWells from 'common/ui/components/simulation-details/mix/computeSelectedWells';
import { getWellContents } from 'common/ui/components/simulation-details/mix/deckContents';
import DeckLayout from 'common/ui/components/simulation-details/mix/DeckLayout';
import PreviewError from 'common/ui/components/simulation-details/mix/Error';
import {
  filterState,
  getStepsAffectingWells,
} from 'common/ui/components/simulation-details/mix/filterState';
import HelpDialogContents from 'common/ui/components/simulation-details/mix/HelpDialogContents';
import { collapseHotels } from 'common/ui/components/simulation-details/mix/hotels';
import ManualInterventionsButton from 'common/ui/components/simulation-details/mix/ManualInterventions/Button';
import ManualInterventionProvider from 'common/ui/components/simulation-details/mix/ManualInterventions/ManualInterventionContext';
import { DeckState, Edge } from 'common/ui/components/simulation-details/mix/MixState';
import MixStateCache from 'common/ui/components/simulation-details/mix/MixStateCache';
import MixView from 'common/ui/components/simulation-details/mix/MixView';
import { isLabwareMovement } from 'common/ui/components/simulation-details/mix/moveLabware';
import Prompt from 'common/ui/components/simulation-details/mix/Prompt';
import RightPanel, {
  DeckItemInfo,
  ExtendedEdgeInfo,
  WellInfo,
} from 'common/ui/components/simulation-details/mix/RightPanel';
import { buildSimulationStateGraph } from 'common/ui/components/simulation-details/mix/SimulationStateGraph';
import { WellHighlightMode } from 'common/ui/components/simulation-details/mix/Well';
import zIndex from 'common/ui/components/simulation-details/mix/zIndex';
import StageRegionList from 'common/ui/components/simulation-details/StageRegion/List';
import StepSlider, { KeyPoint } from 'common/ui/components/simulation-details/StepSlider';
import StepLabel from 'common/ui/components/simulation-details/StepSlider/components/StepLabel';
import Workspace from 'common/ui/components/Workspace/Workspace';
import WorkspaceObserver from 'common/ui/components/Workspace/WorkspaceObserver';
import useDebounce from 'common/ui/hooks/useDebounce';
import { useStateWithURLParams } from 'common/ui/hooks/useStateWithURLParams';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';
import { getPosFromEvent, isDrag, Point2D } from 'common/ui/lib/ClickRecognizer';
import Keys from 'common/ui/lib/keyboard';
import { isMultiSelectClick } from 'common/ui/lib/mouse';
import { RouteHiddenContext } from 'common/ui/lib/router/RouteHiddenContext';

type Props = {
  mixPreview: MixPreview;
  /** So that we can show human-readable plate labels */
  plateTypes: readonly PlateNameData[];
  highlightedPlate?: string;
  overlayText?: { [plate: string]: string };
  isPlaybackEnabled?: boolean;
  design?: DOEDesign;
  stageDetails?: StageDetails[];
};

const NO_KEY_POINTS: readonly KeyPoint[][] = [] as const;
const NO_SELECTED_WELLS: readonly WellLocationOnDeckItem[] = [] as const;

export default function MixScreen(props: Props) {
  const isEnabledManualIntervention = useFeatureToggle('MANUAL_INTERVENTION_HIGHLIGHT');
  return isEnabledManualIntervention ? (
    <ManualInterventionProvider>
      <MixScreenComponent {...props} />
    </ManualInterventionProvider>
  ) : (
    <MixScreenComponent {...props} />
  );
}

/**
 * The whole screen with controls and the fancy MixView UI
 */
function MixScreenComponent({
  mixPreview,
  plateTypes,
  highlightedPlate,
  overlayText,
  isPlaybackEnabled,
  design,
  stageDetails,
}: Props) {
  const context = useContext(RouteHiddenContext);

  const [contentFilter, setContentFilter] = useState<string>(''); // Find wells with specific contents

  const [stageFromURL, setCurrentStage] = useStateWithURLParams({
    paramName: 'stage',
    paramType: 'number',
    defaultValue: 1,
  });
  const [stepFromURL, setAppliedSteps] = useStateWithURLParams({
    paramName: 'step',
    paramType: 'number',
    defaultValue: 0,
  });
  /**
   * Stages are naturally indexed from 1 to N and are always visible in the URL query string
   * e.g. ?stage=1
   *
   * The value of the query string parameter corresponds to the index of the simulation stage
   */
  const currentStageIndex = clamp(
    stageFromURL ? stageFromURL - 1 : 0,
    0,
    mixPreview.stages.length - 1,
  );
  const currentStage = mixPreview.stages[currentStageIndex];
  /**
   * Steps are indexed from 0 where the value of the query string parameter corresponds
   * to the number of steps applied in the simulation stage. Meaning that
   * - step=0 - no steps applied
   * - step=1 - 1 step applied, cursor index is 1 (steps[0] is applied)
   * - step=N - N steps applied, cursor index is N (steps[0], steps[1]..., steps[N] applied)
   *
   * Also ?step=0 is not visible in the URL
   */
  const appliedSteps = clamp(stepFromURL ?? 0, 0, currentStage.length);

  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [policyFilter, setPolicyFilter] = useState<string>(''); // Find edges with specific policy
  const [selectedWells, setSelectedWells] =
    useState<readonly WellLocationOnDeckItem[]>(NO_SELECTED_WELLS);
  const [selectedEdge, setSelectedEdge] = useState<Edge | null>(null);
  const [selectedDeckPositionName, setSelectedDeckPositionName] = useState<string>();
  // If true, show all transfers at once. If false, only show transfers
  // for the `currentStep` selected on the slider.
  const [showWellProvenance, setShowWellProvenance] = useState<boolean>(false);

  // Store the position where the user pressed down the mouse button. Store in a ref, not
  // a state, such that it doesn't trigger re-renders when it changes.
  const pointerDownPosRef = useRef<Point2D | null>(null);

  // Show well provenance when (and only when) the user selects some wells.
  useEffect(() => setShowWellProvenance(selectedWells.length > 0), [selectedWells]);

  const deckLayout = useMemo(() => {
    // In the main preview, we vertically shrink the size of hotels (which comprise
    // multiple vertically stacked plates) so they do not take up lots of vertical space.
    // We only want this behaviour in the preview (e.g., not in the Setup screen), so we
    // only run this function here and not in DeckLayout.
    const deckScaled = collapseHotels(mixPreview.deck);
    return new DeckLayout(deckScaled);
  }, [mixPreview]);

  // Create a cache to store each step that the user navigates to.
  const mixStateCache = useMemo(() => new MixStateCache(mixPreview), [mixPreview]);

  // Compute the state of the deck at the currently viewed step. Using the cache means the
  // step is computed using the closest previous step that's been cached.
  const currentState = useMemo(
    () => mixStateCache.computeState(currentStageIndex, appliedSteps),
    [currentStageIndex, appliedSteps, mixStateCache],
  );

  // The graph is for showing the provenance and we only show that when a well is
  // selected.
  const currentStateGraph = useMemo(
    () =>
      selectedWells || contentFilter
        ? buildSimulationStateGraph(currentState)
        : undefined,
    [contentFilter, currentState, selectedWells],
  );

  // Compute the very last step of the deck for each stage, used to show keypoints on the slider
  const finalStates = useMemo(
    () =>
      mixPreview.stages.map((stage, stageIndex) => {
        const state = mixStateCache.computeState(stageIndex, stage.length);
        const stateGraph = buildSimulationStateGraph(state);
        return { state, stateGraph };
      }),
    [mixPreview.stages, mixStateCache],
  );

  const currentPrompt = useMemo(
    () => currentState.prompts.find(p => p.stepIndex === appliedSteps),
    [appliedSteps, currentState],
  );

  const currentError = useMemo(
    () => currentState.errors.find(p => p.stepIndex === appliedSteps),
    [appliedSteps, currentState],
  );

  // Generate the key steps to highlight on the step slider when no well is selected.
  const defaultKeyPoints = useMemo(
    () =>
      finalStates.map(({ state }, stageIndex) => {
        const stage = mixPreview.stages[stageIndex];
        const allPromptKeyPoints: KeyPoint[] = state.prompts
          .filter(({ stepIndex, automated }) => {
            /**
             * We don't want to show KeyPoint markers for automated labware movements because,
             * in cases where there are many, highlighting all of them would not make sense.
             *
             * However, we want to show markers for manual labware movements along with a prompt.
             */
            return !isLabwareMovement(stage[stepIndex - 1]) || !automated;
          })
          .map<KeyPoint>(({ stepIndex, prompt, automated }) => ({
            step: stepIndex,
            kind: 'prompt',
            isManualIntervention: !automated,
            popper: {
              message: prompt.message,
              duration: prompt.duration_seconds,
            },
          }));
        const allErrorKeyPoints: KeyPoint[] = state.errors.map(({ stepIndex }) => ({
          step: stepIndex,
          kind: 'error',
        }));
        return [...allPromptKeyPoints, ...allErrorKeyPoints];
      }),
    [finalStates, mixPreview.stages],
  );

  // Cache expensive computation of current state of the UI.
  const selectedWellsKeyPoints = useMemo(
    () =>
      selectedWells.length === 0
        ? NO_KEY_POINTS
        : finalStates.map(({ state, stateGraph }) =>
            getStepsAffectingWells(state, stateGraph, selectedWells).map(
              step =>
                ({
                  step,
                  kind: 'transfer',
                } as KeyPoint),
            ),
          ),
    [finalStates, selectedWells],
  );

  /**
   * playbackTime is only set when the user has initiated playback, such that
   * the time shown can be continuous between steps. When adjusting the slider,
   * the playbackTime will be unset and the actual time estimate of the step
   * will be used.
   */
  const [playbackTime, setPlaybackTime] = useState<number | undefined>();

  /**
   * Update the slider with new step number and time. playbackTime is provided
   * by SimulationPlayBack control.
   */
  const handleSliderChange = useCallback(
    (stepCount: number, playbackTime?: number) => {
      setAppliedSteps(stepCount);
      setPlaybackTime(playbackTime);
    },
    [setAppliedSteps],
  );
  const handleStageChange = useCallback(
    (stageIndex: number) => {
      // Here we adjust the URL query string parameter to start from 1 to N
      setCurrentStage(stageIndex + 1);
    },
    [setCurrentStage],
  );
  const handleStageRegionChange = useCallback(
    (stageIndex: number) => {
      /**
       * When switching stages on the workspace we want to reset the amount of `appliedSteps`.
       * Otherwise, the value of `appliedSteps` remains the same and there is a risk
       * of changing from {stage:1,step:1000} to {stage:2,step:1000}
       * where stage2 has only 400 steps etc.
       */
      handleStageChange(stageIndex);
      setAppliedSteps(stageIndex > 0 ? 1 : 0);
    },
    [handleStageChange, setAppliedSteps],
  );

  const handleContentFilterChange = useDebounce(
    useTextFieldChange((text: string) => {
      const value = text.toLowerCase().trim();
      setContentFilter(value);
    }),
    500,
  );

  const handlePolicyFilterChange = useDebounce(
    useTextFieldChange((text: string) => {
      const value = text.toLowerCase().trim();
      setPolicyFilter(value);
    }),
    500,
  );

  const rememberPointerDownPos = useCallback(
    (e: React.PointerEvent) => (pointerDownPosRef.current = getPosFromEvent(e)),
    [],
  );

  // All pointer up events, regardless if they happen on a well, a tipbox or
  // the background.
  const handleAllPointerUp = useCallback((e: React.PointerEvent) => {
    const targetEl = e.target as HTMLElement;
    const isWellClick = targetEl.hasAttribute('data-well');
    const isEdgeClick = targetEl.hasAttribute('data-edge');

    if (
      !isWellClick &&
      !isEdgeClick &&
      !isDrag(pointerDownPosRef.current, e) &&
      !isMultiSelectClick(e) // there is no shift/ctrl pressed
    ) {
      // Unselect all wells
      setSelectedWells(NO_SELECTED_WELLS);
      setSelectedEdge(null);
      setSelectedDeckPositionName(undefined);
    }
    pointerDownPosRef.current = null;
  }, []);

  const handleWellPointerUp = useCallback(
    (clickedLoc: WellLocationOnDeckItem, e: React.PointerEvent) => {
      if (isDrag(pointerDownPosRef.current, e)) {
        // If the user dragged, do nothing. Dragging should never select wells.
        return;
      }

      // Keep event around for setSelectedWells callback
      e.persist();
      setSelectedWells(selectedWells => {
        const { newSelectedWells } = computeSelectedWells(
          selectedWells,
          clickedLoc,
          e,
          SIMULATION_DETAILS_PREVIEW_TAB_ID,
        );
        return newSelectedWells;
      });
    },
    [],
  );

  const getWellInfo = useCallback(
    (loc: WellLocationOnDeckItem): WellInfo => ({
      loc,
      contents: getWellContents(currentState.deck, loc, design),
    }),
    [currentState.deck, design],
  );

  const handleEdgeClick = useCallback((edge: Edge) => setSelectedEdge(edge), []);

  const handleFullscreenClick = () => {
    setIsFullscreen(isFullscreen => !isFullscreen);
  };

  const handleFullscreenButtonPointerDown = (event: React.MouseEvent) => {
    // The screen handles background clicks in order to unselect wells.
    // When someone clicks the button, we want wells to stay selected.
    event.stopPropagation();
  };

  const dialogManager = useDialogManager();
  const handleShowHelp = useCallback(async () => {
    await dialogManager.openDialogPromise(
      'EXECUTION_PREVIEW_HELP',
      InfoDialog,
      HelpDialogContents,
    );
  }, [dialogManager]);

  // If a well is selected, always show that.
  // Otherwise show the well that's under the cursor.
  const wellInfoToShowInRightPanel = useMemo<WellInfo | null>(() => {
    // We could show all (or last 5) selected wells in the right panel.
    // For now, we show just the well that was selected last.
    const lastSelectedLocation = selectedWells[selectedWells.length - 1];
    const lastSelectedWellInfo =
      lastSelectedLocation && getWellInfo(lastSelectedLocation);
    if (lastSelectedWellInfo) {
      // If a well is selected, always show that. A selected well has priority.
      return { ...lastSelectedWellInfo, hover: false };
    }
    return null;
  }, [getWellInfo, selectedWells]);

  const filteredState = useMemo(() => {
    const stepOrDefaultStep = appliedSteps ?? 0;
    return filterState(currentState, {
      edgesFromStepOnly: showWellProvenance ? null : stepOrDefaultStep,
      content: contentFilter,
      policy: policyFilter,
      selectedWells: showWellProvenance ? selectedWells : NO_SELECTED_WELLS,
      // Needed to find edges affecting the selected wells
      stateGraph: currentStateGraph,
    });
  }, [
    currentState,
    showWellProvenance,
    appliedSteps,
    contentFilter,
    policyFilter,
    selectedWells,
    currentStateGraph,
  ]);

  const edgeInfoToShowInRightPanel = useMemo(
    () =>
      extendedEdgeInfo(
        // If we're showing literally one edge on the screen, show its details
        // right away. This way the user doesn't have to mouse over the edge
        // to see details.
        // Single-channel transfers are quite common, so this saves people some
        // time.
        filteredState.edges.length === 1 ? filteredState.edges[0] : selectedEdge,
        filteredState.edges.length !== 1,
        currentState.deck,
      ),
    [selectedEdge, filteredState.edges, currentState.deck],
  );

  const isEnabledMCA = useFeatureToggle('TECAN_FLUENT_MCA384');
  const plateSettings = useMemo(() => {
    /**
     * In regular liquid transfer there are just 2 affected wells:
     * - well which this liquid has been aspirated from
     * - well which this liquid should be dispensed to
     * If there are more than 2 then we have a multi-channeling case
     * and we want to highlight all wells involved in the stamping action
     */
    const isMultiChannelling = filteredState.affectedWells.length > 2;
    const isSelection = selectedWells.length > 0;

    let highlightMode: WellHighlightMode | undefined;
    let wells = selectedWells;
    if (isSelection) {
      highlightMode = WellHighlightMode.SELECTION;
    } else if (isMultiChannelling && isEnabledMCA) {
      highlightMode = WellHighlightMode.STAMPING;
      wells = filteredState.affectedWells;
    }

    return {
      overlayText,
      plateTypes,
      highlightMode,
      selectedWells: wells,
      onWellPointerUp: handleWellPointerUp,
    };
  }, [
    filteredState.affectedWells,
    handleWellPointerUp,
    isEnabledMCA,
    overlayText,
    plateTypes,
    selectedWells,
  ]);

  const [gridVisible, setGridVisible] = useState(true);

  const deckItemInfoToShowInRightPanel = useMemo<DeckItemInfo | undefined>(() => {
    if (!selectedDeckPositionName) {
      return;
    }
    return {
      deckItems: filteredState.deck.items.filter(
        item => item.currentDeckPositionName === selectedDeckPositionName,
      ),
      deckPositionName: selectedDeckPositionName,
    };
  }, [filteredState.deck.items, selectedDeckPositionName]);

  useEffect(() => {
    function handleKeyUp(e: KeyboardEvent) {
      if (context.hidden) {
        // the view is hidden, we do nothing;
        return;
      }
      if (e.key === Keys.ESCAPE) {
        // Unselect all wells
        setSelectedWells(NO_SELECTED_WELLS);
      }
    }
    window.addEventListener('keyup', handleKeyUp);
    return () => window.removeEventListener('keyup', handleKeyUp);
  }, [context.hidden]);

  useMoveToStage(currentStageIndex, appliedSteps, deckLayout);

  const isEnabledManualIntervention = useFeatureToggle('MANUAL_INTERVENTION_HIGHLIGHT');

  // If some wells are selected, show key points for those
  const keyPoints =
    selectedWellsKeyPoints.length > 0 ? selectedWellsKeyPoints : defaultKeyPoints;

  if (context.hidden) {
    // Don't unnecessarily render the large React tree when
    // we are on a different tab of the Simulation Details.
    return null;
  }

  const showRightPanel = !!(
    edgeInfoToShowInRightPanel ||
    wellInfoToShowInRightPanel ||
    deckItemInfoToShowInRightPanel
  );

  return (
    <MixScreenContainer
      isFullscreen={isFullscreen}
      onPointerDown={rememberPointerDownPos}
      onPointerUp={handleAllPointerUp}
    >
      <WorkspaceArea>
        <StepLabel
          currentStage={currentStageIndex}
          appliedSteps={appliedSteps}
          stages={mixPreview.stages}
          timeElapsed={playbackTime || currentState.timeElapsed}
          deckItems={filteredState.deck.items}
        />
        <Filters
          style={{
            gridTemplateAreas: isEnabledManualIntervention
              ? '"manual-intervention liquid-search" ". policy-search"'
              : '"liquid-search policy-search"',
          }}
        >
          {isEnabledManualIntervention && (
            <ManualInterventionsButton keyPoints={defaultKeyPoints} />
          )}
          <SearchLiquid>
            <Search fontSize="small" />
            <FilterInput
              placeholder="Search liquid"
              autoComplete="off"
              onChange={handleContentFilterChange}
              type="search"
            />
          </SearchLiquid>
          <SearchPolicy>
            <Search fontSize="small" />
            <FilterInput
              placeholder="Search policy"
              autoComplete="off"
              onChange={handlePolicyFilterChange}
              type="search"
            />
          </SearchPolicy>
        </Filters>
        <Workspace
          isGridSwitchVisible
          gridVisible={gridVisible}
          setGridVisible={setGridVisible}
          isShowAllButtonVisible
          isShowHelpButtonVisible
          initialShowAll
          onShowHelp={handleShowHelp}
          logCategory={SIMULATION_DETAILS_PREVIEW_TAB_ID}
          canvasControlVariant="light_float"
          unzoomedContent={
            <StageRegionList
              currentStageIndex={currentStageIndex}
              deckLayout={deckLayout}
              onStageChange={handleStageRegionChange}
            />
          }
        >
          <MixView
            gridVisible={gridVisible}
            deckLayout={deckLayout}
            filteredState={filteredState}
            highlightedPlate={highlightedPlate}
            highlightedDeckPositionName={selectedDeckPositionName}
            plateSettings={plateSettings}
            selectedEdge={selectedEdge ?? undefined}
            onEdgeClick={handleEdgeClick}
            onDeckItemPointerUp={setSelectedDeckPositionName}
            steps={currentStage}
          />
        </Workspace>
        <FullScreenButton
          onClick={handleFullscreenClick}
          onPointerDown={handleFullscreenButtonPointerDown}
        >
          {isFullscreen ? <IconFullscreenExit /> : <IconFullscreen />}
        </FullScreenButton>
        {currentError && <PreviewError error={currentError.error} />}
        {currentPrompt && !isEnabledManualIntervention && (
          <Prompt prompt={currentPrompt.prompt} />
        )}
      </WorkspaceArea>
      {showRightPanel && (
        <RightPanelArea>
          <RightPanel
            edgeInfo={edgeInfoToShowInRightPanel}
            wellInfo={wellInfoToShowInRightPanel}
            deckItemInfo={deckItemInfoToShowInRightPanel}
            rules={mixPreview.rules}
            showWellProvenanceToggle
            showWellProvenance={showWellProvenance}
            onShowWellProvenanceChange={setShowWellProvenance}
            factors={design?.factors}
          />
        </RightPanelArea>
      )}
      <SliderArea>
        <StepSlider
          currentStage={currentStageIndex}
          appliedSteps={appliedSteps}
          stages={mixPreview.stages}
          keyPoints={keyPoints}
          stageDetails={stageDetails}
          onStageChange={handleStageChange}
          onStepChange={handleSliderChange}
          allowPlayback={isPlaybackEnabled}
        />
      </SliderArea>
    </MixScreenContainer>
  );
}

/**
 * Info about an edge (i.e. a liquid transfer), plus the contents of the well we
 * transfer from, and the well we transfer to. It is useful to see all the three
 * pieces of information at a glance.
 */
function extendedEdgeInfo(
  edge: Edge | null,
  hover: boolean,
  deck: DeckState,
): ExtendedEdgeInfo | null {
  if (!edge) {
    return null;
  }
  switch (edge.type) {
    case 'liquid_transfer':
    case 'liquid_dispense':
    case 'stamping':
    case 'filtration': {
      const sourceLocation = edge.action.from.loc;
      const targetLocation = edge.action.tipDestination.loc;
      const sourceWellContents = getWellContents(deck, sourceLocation);
      return {
        edge,
        sourceLocation,
        targetLocation,
        sourceWellContents,
        hover,
      };
    }
    case 'move_plate':
      // TODO: show move plate edge info (T2799)
      return null;
  }
}

/**
 * Focuses the workspace view on the current stage when user moves from
 * one stage to another.
 */
function useMoveToStage(
  currentStageIndex: number,
  appliedSteps: number,
  deckLayout: DeckLayout,
) {
  useEffect(() => {
    const regions = deckLayout.getAllStageRegions();

    if (regions.length > 0) {
      const noStepsApplied = currentStageIndex === 0 && appliedSteps === 0;
      const cursorAtStageStart = currentStageIndex > 0 && appliedSteps === 1;

      if (noStepsApplied || cursorAtStageStart) {
        const currentStageRegion = regions[currentStageIndex];
        WorkspaceObserver.dispatch('MoveToX', {
          // Here we want to show both previous and current stages.
          // So we shift by half a width of the current stage.
          x: currentStageRegion.left - currentStageRegion.width / 2,
        });
      }
    }
  }, [appliedSteps, currentStageIndex, deckLayout]);
}

const MixScreenContainer = styled('main', {
  shouldForwardProp: prop => prop !== 'isFullscreen',
})<{ isFullscreen: boolean }>(({ theme, isFullscreen }) => {
  const fullscreenStyles: React.CSSProperties | null = isFullscreen
    ? {
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        height: 'initial',
        zIndex: theme.zIndex.modal,
      }
    : null;

  return {
    display: 'grid',
    gridTemplateAreas: `'workspace right' 'slider slider'`,
    gridTemplateColumns: '1fr auto',
    gridTemplateRows: '1fr auto',

    backgroundColor: Colors.WHITE,
    height: 0,
    flexGrow: 1,

    // Prevent scrolling the entire mix screen if the rightPanel is over length
    overflow: 'hidden',
    ...fullscreenStyles,
  };
});

const FullScreenButton = styled(IconButton)({
  [`&.${iconButtonClasses.root}`]: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: zIndex.workspaceControls,
  },
});

const FILTER_CONTROL_WIDTH = 160;

const Filters = styled('aside')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(3),
  right: 50,
  zIndex: zIndex.workspaceControls,

  display: 'grid',
  columnGap: theme.spacing(5),
  rowGap: theme.spacing(5),
}));

const FilterControl = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  padding: theme.spacing(2, 4),
  width: FILTER_CONTROL_WIDTH,
  border: `1px solid ${theme.palette.background.paper}`,

  overflow: 'hidden',
  borderRadius: theme.spacing(2),
  boxShadow: theme.shadows['3'],
}));

const FilterInput = styled('input')(({ theme }) => ({
  width: 0,
  flexGrow: 1,

  lineHeight: '22px',

  color: theme.palette.text.primary,
  borderRadius: theme.spacing(2),
  border: 'none',
  outline: 'none',
}));

const SearchLiquid = styled(FilterControl)({
  gridArea: 'liquid-search',
});

const SearchPolicy = styled(FilterControl)({
  gridArea: 'policy-search',
});

const WorkspaceArea = styled('section')({
  // We wrap the <Workspace> in an extra div. Without this, the Workspace has
  // 0 height, likely because we mix flexbox and non-flexbox layout.
  gridArea: 'workspace',
  display: 'flex',
  position: 'relative',
});

const RightPanelArea = styled('aside')({
  gridArea: 'right',

  display: 'flex',
  flexDirection: 'column',
  width: 312,
  zIndex: 2,
  boxShadow: '0 0 8px rgba(0,0,0,.15)',
  overflow: 'auto',
});

const SliderArea = styled('footer')({
  gridArea: 'slider',
});
