import React, { useCallback } from 'react';

import { fromColumnVolume, toColumnVolume } from 'common/lib/chromatography';
import { parseMeasurement } from 'common/lib/format';
import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import MeasurementEditor from 'common/ui/components/ParameterEditors/MeasurementEditor';

const CV_UNIT = 'CV';
const UL_UNIT = 'ul';

type Props = {
  onChange: (value?: string) => void;
  /**
   * The RoboColumn volume is needed when converting between ul and CV
   */
  robocolumnVolume?: string;
} & ParameterEditorBaseProps<string>;

/**
 * Allows inputting a load volume in CV (column volume) or ul. When switching
 * the unit, the value will be converted.
 *
 * This is used for specifying load volume in chromatography actions.
 */
export default function ColumnVolumeEditor({
  value,
  onChange,
  isDisabled,
  robocolumnVolume,
}: Props) {
  const handleChange = useCallback(
    (newValue?: string) => {
      const prevAmount = value ? parseMeasurement(value) : undefined;
      const newAmount = newValue ? parseMeasurement(newValue) : undefined;
      // If the unit was changed, then convert the value
      if (newAmount?.unit === UL_UNIT && prevAmount?.unit === CV_UNIT) {
        if (value && robocolumnVolume) {
          onChange(fromColumnVolume(value, robocolumnVolume));
        }
      } else if (newAmount?.unit === CV_UNIT && prevAmount?.unit === UL_UNIT) {
        if (value && robocolumnVolume) {
          onChange(toColumnVolume(value, robocolumnVolume));
        }
      } else {
        onChange(newValue);
      }
    },
    [onChange, robocolumnVolume, value],
  );

  return (
    <MeasurementEditor
      units={robocolumnVolume ? [UL_UNIT, CV_UNIT] : [CV_UNIT]}
      defaultUnit={CV_UNIT}
      value={value}
      onChange={handleChange}
      isDisabled={isDisabled}
      isRequired
    />
  );
}
