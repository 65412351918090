import { isEnabled } from 'common/features/featureTogglesForUI';
import { isMultiChannelling } from 'common/lib/mix';
import { MixPreviewStep, ParallelTransferStep } from 'common/types/mixPreview';
import {
  DeckItemState,
  LiquidTransferEdge,
  StampingEdge,
} from 'common/ui/components/simulation-details/mix/MixState';

type EdgeProps = {
  step: ParallelTransferStep;
  nextStepIndex: number;
  deckItems: Map<string, DeckItemState>;
};

/**
 * Builds an edge for stamping action to be displayed on the MixScreen.
 */
export function makeStampingEdge({
  step,
  nextStepIndex,
  deckItems,
}: EdgeProps): StampingEdge {
  const { action, totalNumberOfChannels, fromDeckPosition, toDeckPosition } =
    getStampingData(step, deckItems);

  return {
    type: 'stamping',
    stepNumber: nextStepIndex,
    action,
    channelCount: totalNumberOfChannels,
    source: {
      name: fromDeckPosition.name,
      deckPositionName: fromDeckPosition.currentDeckPositionName,
    },
    destination: {
      name: toDeckPosition.name,
      deckPositionName: toDeckPosition.currentDeckPositionName,
    },
  };
}

function getStampingData(
  step: ParallelTransferStep,
  deckItems: Map<string, DeckItemState>,
) {
  const totalNumberOfChannels = Object.keys(step.channels).length;
  const firstChannel = step.channels[0];
  const fromDeckPosition = deckItems.get(firstChannel.from.loc.deck_item_id);
  const toDeckPosition = deckItems.get(firstChannel.liquidDestination.loc.deck_item_id);

  if (!fromDeckPosition) {
    throw new Error(`Deck positions not found: ${firstChannel.from.loc.deck_item_id}`);
  }
  if (!toDeckPosition) {
    throw new Error(
      `Deck position not found: ${firstChannel.liquidDestination.loc.deck_item_id}`,
    );
  }
  return {
    action: firstChannel,
    totalNumberOfChannels,
    fromDeckPosition,
    toDeckPosition,
  };
}

/**
 * Data for display on the right-side panel on the MixScreen.
 * It is related to the stamping action and is displayed per single liquid transfer.
 */
export function makeLiquidTransferStampingData(
  step: MixPreviewStep,
  deckItems: Map<string, DeckItemState>,
): LiquidTransferEdge['stamping'] {
  if (isEnabled('TECAN_FLUENT_MCA384') && isMultiChannelling(step)) {
    const stampingData = getStampingData(step, deckItems);

    return {
      sourceName: stampingData.fromDeckPosition.name,
      destinationName: stampingData.toDeckPosition.name,
      channelCount: stampingData.totalNumberOfChannels,
    };
  }

  return undefined;
}
