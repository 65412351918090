import React, { useCallback, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';

import ColumnVolumeEditor from 'client/app/components/Parameters/ChromatographyActions/ColumnVolumeEditor';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import {
  divideColumnVolumes,
  fromColumnVolume,
  loadVolumesToTotalAndFractionVolume,
  residenceTimeToFlowRate,
  toColumnVolume,
  totalAndFractionVolumeToLoadVolumes,
} from 'common/lib/chromatography';
import { roundMeasurement } from 'common/lib/format';
import {
  massToVolume,
  stringRateConverter,
  timeToVolume,
  volumeToMass,
  volumeToTime,
} from 'common/lib/units';
import InlineHelp from 'common/ui/components/InlineHelp/InlineHelp';
import GenericInputEditor from 'common/ui/components/ParameterEditors/GenericInputEditor';
import MeasurementEditor from 'common/ui/components/ParameterEditors/MeasurementEditor';
import {
  MICROLITRES,
  MILLIGRAMS,
  SECONDS,
} from 'common/ui/components/ParameterEditors/unitRegistry';

type FractionInputsProps = {
  isDisabled?: boolean;
  isGradient?: boolean;
  robocolumnVolume?: string;
  loadVolumesInCV?: number[];
  setLoadVolumesInCV: (newLoadVolumesInCV?: number[]) => void;
  /**
   * Required to calculate mass. In units SI prefixed units of g/l if provided
   */
  liquidConcentration?: string;
  /**
   * Required to calculate fraction collection time
   */
  residenceTime?: string;
};

/**
 * Inputs for determining the exact individual load volumes to add to a RoboColumn.
 */
export function LoadCalculationInputs({
  isDisabled,
  isGradient,
  robocolumnVolume,
  liquidConcentration,
  residenceTime,
  loadVolumesInCV,
  setLoadVolumesInCV,
}: FractionInputsProps) {
  const showLoadMassInput = useFeatureToggle('LOAD_ROBOCOLUMN_BY_MASS');
  const calculateMass = useCallback(
    (volume?: string, conc?: string) => {
      // do not round mass as we have no idea if the value will be 12312 mg or
      // 0.0000012424 mg. So rounding to sig figs or decimal places is risky
      return volumeToMilligrams(fromColumnVolume(volume, robocolumnVolume), conc);
    },
    [robocolumnVolume],
  );

  const calculateTime = useCallback(
    (volume?: string, flowRate?: string) => {
      const time = volumeToSeconds(fromColumnVolume(volume, robocolumnVolume), flowRate);
      // For robocolumns, sub-second times are not really possible, so rounding to
      // 2dp to prevent floating point imprecision
      return time ? roundMeasurement(time, 2) : undefined;
    },
    [robocolumnVolume],
  );

  const asColumnVolume = (n: number) => `${n} CV`;
  const initialColumnVolume = loadVolumesToTotalAndFractionVolume(loadVolumesInCV);
  const [totalLoadVolume, setTotalLoadVolume] = useState<string | undefined>(
    initialColumnVolume && asColumnVolume(initialColumnVolume.total),
  );
  const [fractionVolume, setFractionVolume] = useState<string | undefined>(
    initialColumnVolume && asColumnVolume(initialColumnVolume.fraction),
  );
  const [fractionCount, setFractionCount] = useState<string | undefined>(
    // count should be a value > 1. So toFixed dp is precision is good enough
    divideColumnVolumes(totalLoadVolume, fractionVolume)?.toFixed(3),
  );
  const [flowRate, setFlowRate] = useState<string | undefined>(
    residenceTimeToFlowRate(residenceTime, robocolumnVolume),
  );
  const [fractionTime, setFractionTime] = useState<string | undefined>(
    calculateTime(fractionVolume, flowRate),
  );
  const [totalMass, setTotalMass] = useState<string | undefined>(
    calculateMass(totalLoadVolume, liquidConcentration),
  );

  const setFractionCountFromColumnVolume = (total?: string, fraction?: string) => {
    const calcCount = divideColumnVolumes(total, fraction);
    // if fraction is higher than totalLoadColumnVolume, by definition that
    // means we can collect all of the total load volume within one fraction
    const newCount = calcCount === undefined ? 0 : calcCount < 1 ? 1 : calcCount;
    // rounding by decimal places is fine since we are never working with values below 1
    setFractionCount(newCount.toFixed(3));
  };

  const setLoadVolumesInCVAndFractionCount = (total?: string, fraction?: string) => {
    const totalLoadColumnVolume = toColumnVolume(total, robocolumnVolume);
    const fractionColumnVolume = toColumnVolume(fraction, robocolumnVolume);
    const newLoadColumnVolumes = totalAndFractionVolumeToLoadVolumes({
      total: totalLoadColumnVolume,
      fraction: fractionColumnVolume,
    });
    setLoadVolumesInCV(newLoadColumnVolumes);
    setFractionCountFromColumnVolume(totalLoadColumnVolume, fractionColumnVolume);
  };

  const handleFractionVolumeChange = (newFractionVolume?: string) => {
    setLoadVolumesInCVAndFractionCount(totalLoadVolume, newFractionVolume);
    setFractionVolume(newFractionVolume);
    const newFractionTime = calculateTime(newFractionVolume, flowRate);
    newFractionTime && setFractionTime(newFractionTime);
  };

  const handleFractionTimeChange = (newFractionTime?: string) => {
    const newFractionVolume = timeToMicrolitres(newFractionTime, flowRate);
    if (newFractionVolume) {
      setFractionTime(newFractionTime);
      // we performed a calculation so there will be floating point imprecision.
      // Loading sub-ul volumes is impossible in robocolumns, so rounding the
      // volume to 2dp is fine
      const fractionVolumeRounded = roundMeasurement(newFractionVolume, 2);
      setFractionVolume(fractionVolumeRounded);
      setLoadVolumesInCVAndFractionCount(totalLoadVolume, fractionVolumeRounded);
    }
  };

  const handleTotalLoadVolumeChange = (newTotalLoadVolume?: string) => {
    setLoadVolumesInCVAndFractionCount(newTotalLoadVolume, fractionVolume);
    setTotalLoadVolume(newTotalLoadVolume);
    setTotalMass(calculateMass(newTotalLoadVolume, liquidConcentration));
  };

  const handleTotalMassChange = (newTotalMass?: string) => {
    const newTotalLoadVolume = massToMicrolitres(newTotalMass, liquidConcentration);
    if (newTotalLoadVolume) {
      setTotalMass(newTotalMass);
      // we performed a calculation so there will be floating point imprecision.
      // Loading sub-ul volumes is impossible in robocolumns, so rounding the
      // volume to 2dp is fine
      const totalLoadVolumeRounded = roundMeasurement(newTotalLoadVolume, 2);
      setTotalLoadVolume(totalLoadVolumeRounded);
      setLoadVolumesInCVAndFractionCount(totalLoadVolumeRounded, fractionVolume);
    }
  };

  useEffect(() => {
    const newTotalMass = calculateMass(totalLoadVolume, liquidConcentration);
    setTotalMass(newTotalMass);
  }, [calculateMass, liquidConcentration, totalLoadVolume]);

  useEffect(() => {
    // don't chance fraction volume, just recalculate fraction time
    const newFlowRate = residenceTimeToFlowRate(residenceTime, robocolumnVolume);
    setFlowRate(newFlowRate);
    setFractionTime(calculateTime(fractionVolume, flowRate));
  }, [calculateTime, flowRate, fractionVolume, residenceTime, robocolumnVolume]);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="subtitle2">Load Calculations</Typography>
        <InlineHelp>
          <p>
            The following calculations are automatically performed for you:
            <ul>
              <li>
                Fraction collection time (s) = load volume per fraction (l) ÷ flow rate
                (l/s)
              </li>
              <li>
                Load volume per fraction (l) = fraction collection time (s) × flow rate
                (l/s)
              </li>
              <li>
                Num. fractions = total load volume (l) ÷ load volume per fraction (l)
              </li>
              <li>
                Total load volume (l) = total load mass (g) ÷ liquid concentration (g/l)
              </li>
              <li>
                Total load mass (g) = total load volume (l) × liquid concentration (g/l)
              </li>
            </ul>
          </p>
        </InlineHelp>
      </Box>
      <Box>
        <InputLabel shrink>Fraction Collection Time</InputLabel>
        <MeasurementEditor
          units={[SECONDS]}
          defaultUnit={SECONDS}
          value={fractionTime}
          onChange={handleFractionTimeChange}
          isDisabled={isDisabled || !residenceTime || !robocolumnVolume}
          isRequired
        />
        {!residenceTime && (
          <FormHelperText disabled>
            To set fraction times, please ensure a residence time is set.
          </FormHelperText>
        )}
        {!robocolumnVolume && (
          <FormHelperText disabled>
            To set fraction times, please edit robocolumns with identical volumes.
          </FormHelperText>
        )}
      </Box>
      <Box>
        <InputLabel shrink>Max Load Volume Per Fraction</InputLabel>
        <ColumnVolumeEditor
          value={fractionVolume}
          robocolumnVolume={robocolumnVolume}
          onChange={handleFractionVolumeChange}
          isDisabled={isDisabled}
          isRequired
        />
      </Box>
      <Box>
        <InputLabel shrink>Calculated Number of Fractions</InputLabel>
        <GenericInputEditor
          type=""
          value={fractionCount}
          onChange={() => {}}
          isDisabled
        />
      </Box>
      <Box>
        <InputLabel shrink>Total Load Volume</InputLabel>
        <ColumnVolumeEditor
          value={totalLoadVolume}
          robocolumnVolume={robocolumnVolume}
          onChange={handleTotalLoadVolumeChange}
          isDisabled={isDisabled}
          isRequired
        />
      </Box>
      {showLoadMassInput && !isGradient && (
        <Box>
          <InputLabel shrink>Total Load Mass</InputLabel>
          <MeasurementEditor
            units={[MILLIGRAMS]}
            defaultUnit={MILLIGRAMS}
            value={totalMass}
            onChange={handleTotalMassChange}
            isDisabled={isDisabled || !liquidConcentration || !robocolumnVolume}
            isRequired
          />
          {!robocolumnVolume ? (
            <FormHelperText disabled>
              To load by mass, please edit robocolumns with identical volumes.
            </FormHelperText>
          ) : !liquidConcentration ? (
            <FormHelperText disabled>
              To load by mass, ensure the liquid to load has no subcomponents and an
              overall SI prefixed mass concentration (e.g. mg/ml) and that there are no
              errors before this element.
            </FormHelperText>
          ) : undefined}
        </Box>
      )}
    </>
  );
}

function timeToMicrolitres(time?: string, flowRate?: string) {
  return stringRateConverter(
    { initial: time, rate: flowRate, inUnit: MICROLITRES },
    timeToVolume,
  );
}

function volumeToSeconds(volume?: string, flowRate?: string) {
  return stringRateConverter(
    { initial: volume, rate: flowRate, inUnit: SECONDS },
    volumeToTime,
  );
}

function volumeToMilligrams(volume?: string, concentration?: string) {
  return stringRateConverter(
    { initial: volume, rate: concentration, inUnit: MILLIGRAMS },
    volumeToMass,
  );
}

function massToMicrolitres(mass?: string, concentration?: string) {
  return stringRateConverter(
    { initial: mass, rate: concentration, inUnit: MICROLITRES },
    massToVolume,
  );
}
