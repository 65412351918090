import { ReactNode, useEffect } from 'react';
import React from 'react';

import { BrowserRouter, HashRouter } from 'react-router-dom';

import { useFeatureToggle } from 'common/features/useFeatureToggle';

function rewritePath(routingStrategy: 'hash' | 'history', location: Location) {
  if (routingStrategy === 'history') {
    // Rewrite hash-based paths to normal paths.
    if (location.hash.startsWith('#/')) {
      return location.hash.replace(/#\/+/, '/');
    }
  } else if (location.pathname.length > 1) {
    // Rewrite any other normal path than '/' to a hash-based path.
    return `/#${location.pathname}${location.search}`;
  }

  return undefined;
}

/**
 * This lets us switch between `HashRouter` and `BrowserRouter` based on the `HISTORY_ROUTING`
 * feature toggle. This will faciliate testing the new routing strategy without having to turn
 * it on for the whole environment.
 *
 * TODO(SYN-8512): If everything works, remove the feature toggle and only use history-based routing.
 * But keep the effect to rewrite legacy hash-based URLs to path-based ones.
 */
export default function ToggleRouter({ children }: { children?: ReactNode }) {
  const isHistoryRoutingEnabled = useFeatureToggle('HISTORY_ROUTING');

  // Rewrite URLs from hash or path based style based on the enabled router type.
  // This is necessary to keep existing links into the platform working.
  useEffect(() => {
    const newURL = rewritePath(isHistoryRoutingEnabled ? 'history' : 'hash', location);

    if (newURL) {
      history.replaceState({}, '', newURL);
    }
  }, [isHistoryRoutingEnabled]);

  if (isHistoryRoutingEnabled) {
    return <BrowserRouter>{children}</BrowserRouter>;
  }

  return <HashRouter>{children}</HashRouter>;
}

export { rewritePath as rewritePathTestOnly };
