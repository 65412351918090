import React, { useMemo, useState } from 'react';

import FormControlLabel, {
  formControlLabelClasses,
} from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import useDevicesForFilter from 'client/app/apps/plate-library/useDevicesForFilter';
import { SimpleDevice } from 'common/types/device';
import Colors from 'common/ui/Colors';
import Checkbox from 'common/ui/components/Checkbox';
import filterDevices from 'common/ui/components/DeviceLibrary/filterDevices';
import FilterChip from 'common/ui/components/FilterChip/FilterChip';
import LinearProgress from 'common/ui/components/LinearProgress';
import SearchField from 'common/ui/components/SearchField';

export type DeviceOption = Pick<SimpleDevice, 'id' | 'name' | 'imageUrl' | 'model'> & {
  accessibleDevices: Pick<SimpleDevice, 'id' | 'imageUrl' | 'model'>[];
};

type Props = {
  selectedIds: string[];
  onChange: (selectedIds: string[]) => void;
  className?: string;
};

const EMPTY_SELECTION: [] = [];

export default function DeviceFilter(props: Props) {
  const { onChange, selectedIds, className } = props;

  const [options, isDeviceQueryLoading, manualDeviceId] = useDevicesForFilter();

  // We wish to hide the "Manual" option because:
  // 1. No filtering occurs when selecting this
  // 2. Users may be confused by seeing this listed (or pre-selected in a workflow) where a device is usually expected
  const optionsWithoutManualDevice = useMemo(
    () => options.filter(option => option.id !== manualDeviceId),
    [manualDeviceId, options],
  );

  const selectedIdsWithoutManualDevice = useMemo(() => {
    const index = selectedIds.findIndex(id => id === manualDeviceId);
    if (index !== -1) {
      return selectedIds.toSpliced(index);
    }
    return selectedIds;
  }, [manualDeviceId, selectedIds]);

  // Update the label of the chip depending on whether there is 0, 1, 2+ items.
  const valueLabel =
    selectedIdsWithoutManualDevice.length === 0
      ? 'Device'
      : selectedIdsWithoutManualDevice.length === 1
      ? `${
          optionsWithoutManualDevice.find(
            option => option.id === selectedIdsWithoutManualDevice[0],
          )!.name
        }`
      : `Devices - ${selectedIdsWithoutManualDevice.length} selected`;

  const chipLabelVariant =
    selectedIdsWithoutManualDevice.length > 0 ? 'filled' : 'outlined';

  const clearSelection = () => onChange(EMPTY_SELECTION);

  return (
    <FilterChip
      heading="Filter by device"
      chipLabel={valueLabel}
      chipLabelVariant={chipLabelVariant}
      filterValue={selectedIdsWithoutManualDevice}
      onDelete={clearSelection}
      isActive={selectedIdsWithoutManualDevice.length > 0}
      className={className}
      popoverContent={
        <PopoverComponent
          options={optionsWithoutManualDevice}
          selectedIds={selectedIdsWithoutManualDevice}
          onChange={onChange}
          isDeviceQueryLoading={isDeviceQueryLoading}
        />
      }
    />
  );
}

const PopoverComponent = ({
  options,
  selectedIds,
  onChange,
  isDeviceQueryLoading,
}: { isDeviceQueryLoading: boolean; options: DeviceOption[] } & Omit<
  Props,
  'className'
>) => {
  const [searchQuery, setSearchQuery] = useState('');
  const optionsForDisplay = filterDevices(options, searchQuery);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedOption = options.find(device => device.id === e.target.id);
    if (!selectedOption) {
      return;
    }
    if (e.target.checked) {
      onChange([...selectedIds, selectedOption.id]);
    } else {
      const newIds = selectedIds.filter(id => id !== selectedOption.id);
      onChange(newIds);
    }
  };

  return (
    <PopoverContent>
      <>
        <StyledSearchField
          variant="standard"
          label="Search"
          fullWidth
          defaultValue={searchQuery}
          onQueryChange={setSearchQuery}
        />
        {isDeviceQueryLoading ? (
          <LinearProgress />
        ) : optionsForDisplay.length === 0 ? (
          <Typography>No devices found</Typography>
        ) : (
          optionsForDisplay.map(option => {
            return (
              <StyledFormControlLabel
                key={option.id}
                control={
                  <Checkbox
                    onChange={handleChange}
                    id={option.id}
                    checked={selectedIds.includes(option.id)}
                  />
                }
                label={
                  <DeviceLabel
                    imageUrl={option.imageUrl}
                    model={option.model}
                    name={option.name}
                    accessibleDevices={option.accessibleDevices}
                  />
                }
              />
            );
          })
        )}
      </>
    </PopoverContent>
  );
};

type DeviceLabelProps = Pick<
  DeviceOption,
  'name' | 'imageUrl' | 'model' | 'accessibleDevices'
>;

const DEVICE_NOT_FOUND_IMAGE = '/app/cdn/assets/devices/Void.png';

const DeviceLabel = ({
  imageUrl: imgUrl,
  model,
  name,
  accessibleDevices,
}: DeviceLabelProps) => {
  return (
    <>
      <Typography variant="h5" sx={{ gridColumn: '1 / 3' }}>
        {name}
      </Typography>
      <DeviceImage src={imgUrl ?? DEVICE_NOT_FOUND_IMAGE} />
      <Typography display="inline">{model}</Typography>
      {accessibleDevices.map(accessibleDevice => (
        <React.Fragment key={accessibleDevice.id}>
          <DeviceImage src={accessibleDevice.imageUrl ?? DEVICE_NOT_FOUND_IMAGE} />
          <Typography display="inline">{accessibleDevice.model}</Typography>
        </React.Fragment>
      ))}
    </>
  );
};

//#region Styles

const PopoverContent = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto auto 1fr',
  minWidth: '200px',
  maxWidth: '400px',
  marginTop: theme.spacing(3),
}));

const StyledSearchField = styled(SearchField)(({ theme }) => ({
  paddingBottom: theme.spacing(4),
  position: 'sticky',
  top: 0,
  backgroundColor: Colors.WHITE,
  zIndex: 1,
  gridColumn: '1 / 4',
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  [`&.${formControlLabelClasses.root}`]: {
    alignItems: 'flex-start',
    display: 'grid',
    gridColumn: '1 / 4',
    gridTemplateColumns: 'subgrid',
  },
  [`& .${formControlLabelClasses.label}`]: {
    padding: '9px 0px',
    display: 'grid',
    gridColumn: '2 / 4',
    gridTemplateColumns: 'subgrid',
    gap: theme.spacing(3),
    alignItems: 'center',
  },
  [':not(:last-child)']: {
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderColor: Colors.GREY_30,
  },
}));

const DeviceImage = styled('img')(({ theme }) => ({
  width: 26,
  padding: theme.spacing(1),
}));

//#endregion
