import React, { useCallback } from 'react';

import { styled } from '@mui/material/styles';

import { EditProtocolInstanceHeader } from 'client/app/apps/protocols/EditProtocolInstanceHeader';
import { InputStep } from 'client/app/apps/protocols/InputStep';
import { InputStepList } from 'client/app/apps/protocols/InputStepList';
import {
  useComplexParameterEditorDialogManager,
  useProtocolsParamState,
} from 'client/app/apps/protocols/lib/utils';
import { OutputStepPreview } from 'client/app/apps/protocols/OutputStepPreview';
import { Step } from 'client/app/apps/protocols/useGetData';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import { ParameterValue } from 'common/types/bundle';
import { getElementId, getElementParameterName, Schema } from 'common/types/schema';
import Colors from 'common/ui/Colors';
type Props = {
  steps: Step[];
  workflowSchema: Schema;
  protocolName: string;
};

export const EditProtocolInstance = ({ steps, workflowSchema, protocolName }: Props) => {
  const parameters = useWorkflowBuilderSelector(state => state.parameters);
  const elementInstances = useWorkflowBuilderSelector(state => state.elementInstances);
  const dialogs = useComplexParameterEditorDialogManager();

  const { selectedStep, handleSelectStep } = useProtocolsParamState(steps);

  const handleOnSimulate = useCallback(() => {
    const updatedProtocolParams: { [inputId: string]: ParameterValue } = {};
    workflowSchema.inputs?.forEach(input => {
      const elementInstanceId = getElementId(input.path);
      const parameterName = getElementParameterName(input.path);
      const elementInstanceName = elementInstances.find(
        elementInstance => elementInstance.Id === elementInstanceId,
      )?.name;
      if (elementInstanceName && parameterName) {
        const paramValue = parameters[elementInstanceName][parameterName];
        updatedProtocolParams[input.id] = paramValue;
      }
    });
    // TODO - Submit callback with data, right now we just log it out
    // for dev validation purposes.
    console.log(updatedProtocolParams);
  }, [elementInstances, parameters, workflowSchema.inputs]);

  if (!workflowSchema.inputs || !workflowSchema.outputs) {
    // TODO - Handle this state better in the UI.
    return <p>No inputs or outputs found</p>;
  }

  return (
    <>
      <EditProtocolInstanceHeader
        protocolName={protocolName}
        handleOnSimulate={handleOnSimulate}
      />
      <Wrapper>
        <InputStepList
          steps={steps}
          onSelectStep={handleSelectStep}
          selectedStepId={selectedStep.id}
        />
        <InputsAndOutputsWrapper>
          <InputStep
            step={selectedStep}
            inputs={workflowSchema.inputs.filter(input =>
              selectedStep.inputs.map(input => input.id).includes(input.id),
            )}
            outputs={workflowSchema.outputs.filter(output =>
              selectedStep.outputs.map(output => output.id).includes(output.id),
            )}
          />
          <OutputStepPreview noOutput={selectedStep.outputs.length === 0} />
        </InputsAndOutputsWrapper>
        {dialogs}
      </Wrapper>
    </>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplate: `
      "list inputsAndOutputs inputsAndOutputs" minmax(400px, 1200px)
      / auto 1fr 1fr`,
  padding: theme.spacing(8),
  gap: theme.spacing(7),
  height: '100%',
  overflow: 'auto',
  backgroundColor: Colors.GREY_10,
}));

const InputsAndOutputsWrapper = styled('div')({
  gridArea: 'inputsAndOutputs',
  display: 'flex',
  overflowX: 'auto',
});
