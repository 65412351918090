import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import PrintIcon from '@mui/icons-material/Print';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import clamp from 'lodash/clamp';

import PlatePrepScreenPrintable from 'client/app/components/PlatePrep/PlatePrepScreenPrintable';
import PlatesSidebar from 'client/app/components/PlatePrep/PlatesSidebar';
import PlateView from 'client/app/components/PlatePrep/PlateView';
import { byName } from 'common/lib/strings';
import { Plate, WellLocationOnDeckItem } from 'common/types/mix';
import { MixPreview } from 'common/types/mixPreview';
import Fab from 'common/ui/components/Fab';
import Print from 'common/ui/components/Print';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import {
  getDeckItemStates,
  getWellContents,
  platesOnly,
} from 'common/ui/components/simulation-details/mix/deckContents';
import DeckLayout from 'common/ui/components/simulation-details/mix/DeckLayout';
import MixStateCache from 'common/ui/components/simulation-details/mix/MixStateCache';
import RightPanel from 'common/ui/components/simulation-details/mix/RightPanel';
import StepSlider, { KeyPoint } from 'common/ui/components/simulation-details/StepSlider';
import StepLabel from 'common/ui/components/simulation-details/StepSlider/components/StepLabel';
import { useStateWithURLParams } from 'common/ui/hooks/useStateWithURLParams';
import { RouteHiddenContext } from 'common/ui/lib/router/RouteHiddenContext';

export type PlatePrepProps = {
  mixPreview: MixPreview;
  /**
   * Displayed when users print the page
   */
  simulationName?: string;
  /**
   * Whether to display the FAB that allows users to print the page
   */
  showPrintButton?: boolean;
  /*
   * This component can be used as a dialog, to allow users to select a plate
   * from a scheduled simulation. In this case, we'll show a special `Setup` screen:
   * users are interested only in the resulting plate (i.e. the last step), thus we
   * default to that step and hide the slider.
   */
  dialogProps?: { handleSelectPlate: (plate: Plate | null) => void };
  /**
   * Amount of whitespace surrounding a plate on the plate screen.
   *
   * Measured in theme spacing ladder: 1, 2, 3 ... 12
   */
  padding?: number;
};

// On the plate prep screen, we show no key points
const NO_SLIDER_KEY_POINTS: readonly KeyPoint[][] = [];

function PlatePrepScreen({
  mixPreview,
  simulationName,
  showPrintButton,
  dialogProps,
  padding,
}: PlatePrepProps) {
  const [stageFromURL, setCurrentStage] = useStateWithURLParams({
    paramName: 'stage',
    paramType: 'number',
    defaultValue: 1,
  });
  const [stepFromURL, setAppliedSteps] = useStateWithURLParams({
    paramName: 'step',
    paramType: 'number',
    defaultValue: 0,
  });
  /**
   * Stages are naturally indexed from 1 to N and are always visible in the URL query string
   * e.g. ?stage=1
   *
   * The value of the query string parameter corresponds to the index of the simulation stage
   */
  const currentStageIndex = clamp(
    stageFromURL ? stageFromURL - 1 : 0,
    0,
    mixPreview.stages.length - 1,
  );
  const currentStage = mixPreview.stages[currentStageIndex];
  /**
   * Steps are indexed from 0 where the value of the query string parameter corresponds
   * to the number of steps applied in the simulation stage. Meaning that
   * - step=0 - no steps applied
   * - step=1 - 1 step applied, cursor index is 1 (steps[0] is applied)
   * - step=N - N steps applied, cursor index is N (steps[0], steps[1]..., steps[N] applied)
   *
   * Also ?step=0 is not visible in the URL
   */
  const appliedSteps = clamp(stepFromURL ?? 0, 0, currentStage.length);

  const [selectedPlateId, setSelectedPlateId] = useState<string | null>(null);
  const [wellLocationUnderCursor, setWellLocationUnderCursor] =
    useState<WellLocationOnDeckItem | null>(null);
  const screenToPrintRef = useRef<HTMLDivElement | null>(null);
  const platePrepRef = useRef<HTMLDivElement | null>(null);

  const deckLayout = useMemo(() => new DeckLayout(mixPreview.deck), [mixPreview]);
  const mixStateCache = useMemo(
    () => new MixStateCache(mixPreview, { noLabwareMovements: true }),
    [mixPreview],
  );

  const liquidColors = useMemo(
    () =>
      LiquidColors.createUsingColorGraph(
        getDeckItemStates(deckLayout.deck),
        currentStage,
      ),
    [currentStage, deckLayout.deck],
  );

  // Note that the MixPreview never changes while the user interacts with the
  // screen. The MixPreview is fetched once and stored in state. It is the step
  // that affects the result.
  const currentMixState = useMemo(
    () => mixStateCache?.computeState(currentStageIndex, appliedSteps),
    [currentStageIndex, appliedSteps, mixStateCache],
  );

  const plates = useMemo(
    () => currentMixState && platesOnly(currentMixState.deck.items).sort(byName),
    [currentMixState],
  );

  const handleSliderChange = useCallback(
    (stepCount: number) => {
      setAppliedSteps(stepCount);
    },
    [setAppliedSteps],
  );
  const handleStageChange = useCallback(
    (stageIndex: number) => {
      // Here we adjust the URL query string parameter to start from 1 to N
      setCurrentStage(stageIndex + 1);
    },
    [setCurrentStage],
  );

  const onWellPointerDown = useCallback(
    (loc: WellLocationOnDeckItem) => {
      setWellLocationUnderCursor(loc);
    },
    [setWellLocationUnderCursor],
  );

  const handleSelectPlateInSidebar = useCallback((clickedPlateIdInSidebar: string) => {
    setWellLocationUnderCursor(null);
    setSelectedPlateId(clickedPlateIdInSidebar);
  }, []);

  const wellInfoToShowInRightPanel = useMemo(() => {
    if (wellLocationUnderCursor) {
      return {
        loc: wellLocationUnderCursor,
        contents: getWellContents(currentMixState.deck, wellLocationUnderCursor),
        hover: true,
      };
    }
    return null;
  }, [currentMixState, wellLocationUnderCursor]);

  const selectedPlate = useMemo(() => {
    const plates = platesOnly(currentMixState.deck.items);
    if (selectedPlateId) {
      return plates.find(plate => plate.id === selectedPlateId) || null;
    }
    // Return the first plate, e.g. when rendering screen for the first time
    return plates.length > 0 ? plates[0] : null;
  }, [currentMixState, selectedPlateId]);

  // If a user selects a plate, update the parent's state with the selected plate
  useEffect(() => {
    if (!dialogProps) {
      return;
    }
    dialogProps.handleSelectPlate(selectedPlate);

    // Default to the last step, as that's what users are interested in.
    const lastStageIndex = mixPreview.stages.length - 1;
    setCurrentStage(lastStageIndex);
    setAppliedSteps(mixPreview.stages[lastStageIndex].length);
  }, [dialogProps, mixPreview.stages, selectedPlate, setCurrentStage, setAppliedSteps]);

  const hiddenContext = useContext(RouteHiddenContext);
  if (hiddenContext.hidden) {
    // Don't unnecessarily render the large React tree when
    // we are on a different tab of the Simulation Details.
    return null;
  }

  return (
    <>
      <Container ref={platePrepRef}>
        <PlatesSidebar
          plates={plates}
          highlightedWellLocation={wellLocationUnderCursor}
          selectedPlate={selectedPlate}
          liquidColors={liquidColors}
          onPlateClick={handleSelectPlateInSidebar}
          onWellMouseEnter={onWellPointerDown}
        />
        <Content>
          <StepLabel
            currentStage={currentStageIndex}
            appliedSteps={appliedSteps}
            stages={mixPreview.stages}
            deckItems={currentMixState.deck.items}
            timeElapsed={currentMixState.timeElapsed}
          />
          <Stack flexGrow={1} direction="row">
            <Stack flexGrow={1}>
              <PlateViewContainer padding={padding}>
                {selectedPlate && (
                  <PlateView
                    key={selectedPlate.id}
                    deckLayout={deckLayout}
                    highlightedWellLocation={wellLocationUnderCursor}
                    plate={selectedPlate}
                    liquidColors={liquidColors}
                    onWellPointerDown={onWellPointerDown}
                  />
                )}
              </PlateViewContainer>
            </Stack>
            <Stack width={300}>
              <RightPanel wellInfo={wellInfoToShowInRightPanel} edgeInfo={null} />
            </Stack>
          </Stack>
          {!dialogProps && (
            <StepSlider
              currentStage={currentStageIndex}
              appliedSteps={appliedSteps}
              stages={mixPreview.stages}
              keyPoints={NO_SLIDER_KEY_POINTS}
              onStageChange={handleStageChange}
              onStepChange={handleSliderChange}
            />
          )}
        </Content>
      </Container>
      <div ref={screenToPrintRef} style={{ display: 'none' }}>
        <PlatePrepScreenPrintable
          selectedPlate={selectedPlate!}
          deckLayout={deckLayout}
          simulationName={simulationName}
          liquidColors={liquidColors}
        />
      </div>
      {!!showPrintButton && (
        <Print
          trigger={() => {
            return (
              <Fab
                icon={<PrintIcon />}
                color="inherit"
                size="small"
                styleOverrides={{ bottom: 80 }}
              />
            );
          }}
          // When users click print, make the printable PlatePrep visible
          // and hide the regular PlatePrep not to break the layout
          onBeforeGetContent={() => {
            screenToPrintRef.current!.style.display = 'block';
            platePrepRef.current!.style.display = 'none';
            return;
          }}
          content={() => screenToPrintRef.current!}
          // After users close the print dialog, restore to normal PlatePrep layout
          onAfterPrint={() => {
            screenToPrintRef.current!.style.display = 'none';
            platePrepRef.current!.style.display = 'flex';
            return;
          }}
        />
      )}
    </>
  );
}

const Container = styled('main')({
  display: 'flex',
  alignItems: 'stretch',
  flexGrow: 1,
  height: 0,
});

const Content = styled(Stack)({
  flex: 4,
  flexDirection: 'column',
  position: 'relative',
});

const PlateViewContainer = styled('div')<{ padding?: number }>(
  ({ theme, padding = 2 }) => ({
    height: 0,
    flexGrow: 1,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    padding: theme.spacing(padding),
  }),
);

export default PlatePrepScreen;
