import React, { useCallback, useState } from 'react';

import * as FeatureToggles from 'common/features/featureTogglesForUI';
/**
 * FeatureContext allows React components to subscribe to changes to feature
 * toggles so that they automatically re-render when a feature is toggled.
 *
 * Example usage:
 *   const featureToggles = useContext(FeatureTogglesContext);
 *   if (featureToggles.isEnabled('MY_FEATURE')) {
 *     ...
 *   }
 */
export const FeatureTogglesContext = React.createContext({
  isEnabled: FeatureToggles.isEnabled,
  setEnabled: FeatureToggles.setEnabled,
  setAll: FeatureToggles.setAll,
  loadFromSession: FeatureToggles.getAll,
  /**
   * If users opened the dialog, we assume they have changed some
   * values. Users changes should override the defaults we get
   * from the backend.
   */
  hasOpenedFeatureTogglesDialog: FeatureToggles.hasOpenedFeatureTogglesDialog,
  setHasOpenedFeatureTogglesDialog: FeatureToggles.setHasOpenedFeatureTogglesDialog,
});

export function FeatureTogglesContextProvider(props: { children: React.ReactNode }) {
  // We do not need states of feature toggles in our state because
  // isEnabled/setEnabled work with browser session storage.  We'll only keep a
  // counter as a way of forcing a re-render on every call to
  // setEnabled(), so that subscribers to the context get notified of changes.
  const [_, setCounter] = useState(0);

  const setEnabled = useCallback((name: FeatureToggles.FeatureName, enabled: boolean) => {
    FeatureToggles.setEnabled(name, enabled);
    setCounter(prev => prev + 1);
  }, []);
  return (
    <FeatureTogglesContext.Provider
      value={{
        isEnabled: FeatureToggles.isEnabled,
        setEnabled,
        setAll: FeatureToggles.setAll,
        loadFromSession: FeatureToggles.getAll,
        hasOpenedFeatureTogglesDialog: FeatureToggles.hasOpenedFeatureTogglesDialog,
        setHasOpenedFeatureTogglesDialog: FeatureToggles.setHasOpenedFeatureTogglesDialog,
      }}
    >
      {props.children}
    </FeatureTogglesContext.Provider>
  );
}
