import { Position2d } from 'common/types/Position';
import DeckLayout, {
  Point2D,
} from 'common/ui/components/simulation-details/mix/DeckLayout';
import {
  DeckItemState,
  Edge,
} from 'common/ui/components/simulation-details/mix/MixState';

type StraightEdge = {
  start: Point2D;
  end: Point2D;
};

// How far to bend perpendicular to the line. 0 is no bend (straight line).
// Anything larger than the max might produce edges that are out of bounds
// of the deck.
const MAX_BEND = 0.4;
// If we can, we'll try to keep the bend factor of edges this far apart.
// If there are too many edges, we'll use a smaller factor to mash edges closer
// together: if there are simply too many edges to render, there is no way to
// avoid overlap. This is a simple, best-effort heuristic.
const BEND_GAP = 0.06;

export type RoutedEdge = {
  edge: Edge;
  curve: QuadraticBezierCurve;
  arrowAtEnd: boolean;
  pathLengthSquared: number;
};

export function routeAllEdges(
  edges: readonly Edge[],
  deckItems: readonly DeckItemState[],
  deckLayout: DeckLayout,
): RoutedEdge[] {
  const { startBend, endBend } = _getBendRange(edges.length);
  return edges.map((edge, index) => {
    const { straightEdge, arrowAtEnd } = _straightEdge(edge, deckItems, deckLayout);
    // This is a very simple solution that could be improved. For example, we
    // could find clusters of edges that are *likely* to overlap in a confusing
    // way (e.g. similar direction and close to each other) and then compute
    // the bend range separately in each cluster.
    // That would give better results in cases there are multiple distinct groups
    // of edges on the screen.
    const bendFactor = startBend + (index / edges.length) * (endBend - startBend);
    const curve = bentSvgPath(straightEdge.start, straightEdge.end, bendFactor);
    const pathLengthSquared = squaredDistance(straightEdge.start, straightEdge.end);
    return {
      edge,
      curve,
      arrowAtEnd,
      pathLengthSquared,
    };
  });
}

// We bend each edge sligthly differently, to reduce the chances of edges
// overlapping each other. If there are few edges, we don't have to bend them
// to much. If there are a lot of edges, we bend in the other direction, too,
// all the way up to -MAX_BEND.
function _getBendRange(numEdges: number) {
  // We can bend this far in the other direction, not more.
  const minBend = -MAX_BEND;
  return {
    // First edge gets this bend
    startBend: MAX_BEND,
    // Last edge gets this bend
    endBend: Math.max(minBend, MAX_BEND - numEdges * BEND_GAP),
  };
}

const CHANNELS_PER_COLUMN = 8;

/**
 * For liquid movements, multiple tips may be aspirating or dispensing to the same
 * location in the same step (multi-channelling). In this case, we spread out the transfer
 * edges within the well.
 *
 * This function returns a relative coordinate (ie x and y between 0 and 1) of a channel,
 * given a list of all channels involved in the liquid movement.
 *
 * If there are 8 channels or less, all channels will be spread out vertically. More than
 * 8 channels, then the channels are spread into columns of 8 channels.
 */
function getPositionInWell(channelIndex: number, channelCount: number): Position2d {
  // Divide the number of channels by 8 to get the number of columns
  const columnCount = Math.ceil(channelCount / CHANNELS_PER_COLUMN);
  const column = Math.floor(channelIndex / CHANNELS_PER_COLUMN);
  const channelsInColumn = Math.min(channelCount, CHANNELS_PER_COLUMN);
  const row = channelIndex % CHANNELS_PER_COLUMN;

  // Add 0.5 to the Channel Index so that the position will never be 0 (very top of the
  // well). For example, if there are 2 channels, the first would be 0.5 and the second
  // 1.5. Diving this by 2 gives us the positions 0.25 and 0.75.
  return {
    x: (column + 0.5) / columnCount,
    y: (row + 0.5) / channelsInColumn,
  };
}

/**
 * Compute the start and end coordinates of an edge
 */
const getEdgeVertices = (
  edge: Edge,
  deckLayout: DeckLayout,
  deckItems: readonly DeckItemState[],
): Point2D[] => {
  switch (edge.type) {
    case 'liquid_dispense':
    case 'filtration':
    case 'liquid_transfer': {
      const positionInSourceWell = getPositionInWell(
        edge.channelsAspiratingFromWell.indexOf(edge.channel),
        edge.channelsAspiratingFromWell.length,
      );
      const positionInDestWell = getPositionInWell(
        edge.channelsDispensingToWell.indexOf(edge.channel),
        edge.channelsDispensingToWell.length,
      );
      return [
        deckLayout.getWellPosition(deckItems, edge.from, positionInSourceWell),
        deckLayout.getWellPosition(deckItems, edge.to, positionInDestWell),
      ];
    }
    case 'move_plate':
      return [
        deckLayout.getDeckPositionCenter(edge.fromDeckPositionName),
        deckLayout.getDeckPositionCenter(edge.toDeckPositionName),
      ];
    case 'stamping': {
      const startPoint: Point2D = deckLayout.getDeckPositionCenter(
        edge.source.deckPositionName,
      );
      const endPosition = deckLayout.getDeckPositionDimensions(
        edge.destination.deckPositionName,
      );
      const possibleEndPoints = [
        // left center
        {
          x: endPosition.left,
          y: endPosition.top + endPosition.height / 2,
        },
        // right center
        {
          x: endPosition.left + endPosition.width,
          y: endPosition.top + endPosition.height / 2,
        },
        // center top
        {
          x: endPosition.left + endPosition.width / 2,
          y: endPosition.top,
        },
        // center bottom
        {
          x: endPosition.left + endPosition.width / 2,
          y: endPosition.top + endPosition.height,
        },
      ];

      let endPoint: Point2D = {
        x: Number.POSITIVE_INFINITY,
        y: Number.POSITIVE_INFINITY,
      };
      for (const point of possibleEndPoints) {
        if (squaredDistance(startPoint, point) < squaredDistance(startPoint, endPoint)) {
          endPoint = point;
        }
      }

      return [startPoint, endPoint];
    }
  }
};

// When an edge points from right to left, the label would normally be at the
// bottom side. We want to avoid this.
// Therefore, when an edge points from right to left, we swap its end points,
// and draw the line from left to right. The line looks the same, and the label
// is now above the line, which is great!
// We just need to put the arrowhead in the correct place.
function _straightEdge(
  edge: Edge,
  deckItems: readonly DeckItemState[],
  deckLayout: DeckLayout,
): {
  straightEdge: StraightEdge;
  // If true, show arrow at the end. If false, edge is flipped so show arrow at
  // the start.
  arrowAtEnd: boolean;
} {
  const [point1, point2] = getEdgeVertices(edge, deckLayout, deckItems);
  if (point1.x < point2.x) {
    return {
      straightEdge: {
        start: point1,
        end: point2,
      },
      arrowAtEnd: true,
    };
  } else {
    return {
      straightEdge: {
        start: point2,
        end: point1,
      },
      // Edge points from right to left. We flip it so that it points from
      // left to right. But the arrowhead should still be on the left.
      // That is, the arrowhead is now at the start.
      arrowAtEnd: false,
    };
  }
}

/**
 * An edge line is represented as a Quadratic curve (see
 * https://developer.mozilla.org/en-US/docs/Web/SVG/Tutorial/Paths#Bézier_Curves).
 */
export type QuadraticBezierCurve = {
  start: Point2D;
  end: Point2D;
  control: Point2D;
  /**
   * The point mid-way along the curve, used to position the label on the SVG
   * path.
   */
  center: Point2D;
  /**
   * The angle between to start and end points, used to rotate the label on the
   * SVG path.
   */
  angle: number;
};

function bentSvgPath(
  start: Point2D,
  end: Point2D,
  bendFactor: number,
): QuadraticBezierCurve {
  const controlPoint = _interpolate(start, end, 0.5);
  const p = _perpendicular(start, end);
  const control = {
    x: controlPoint.x + p.x * bendFactor,
    y: controlPoint.y + p.y * bendFactor,
  };
  // Compute the center point of the quadratic bezier; this is where the label
  // will appear. The formula is the same for X and Y. Where t is a point
  // between 0 and 1 along the curve:
  //  x = (1-t) * ((1-t) * ControlX + t * StartX) + t * ((1-t) * ControlX + t * EndX)
  //
  // For the center point (t = 0.5), this simplifies to:
  //  x = (StartX + 2 * ControlX + EndX) / 4
  //
  // https://en.wikipedia.org/wiki/Bézier_curve#Quadratic_Bézier_curves
  const center = {
    x: (start.x + 2 * control.x + end.x) / 4,
    y: (start.y + 2 * control.y + end.y) / 4,
  };

  const angleRad = Math.atan2(end.y - start.y, end.x - start.x);
  const angle = (angleRad * 180) / Math.PI;
  return { start, end, control, center, angle };
}

// Interpolate along the line between two points
function _interpolate(start: Point2D, end: Point2D, factor: number): Point2D {
  return {
    x: start.x + factor * (end.x - start.x),
    y: start.y + factor * (end.y - start.y),
  };
}

// Return perpendicular vector to the line connecting the two points.
// The vector is not normalized but this actually looks good for our purpose of
// rendering edges in the Mix Graph UI.
function _perpendicular(start: Point2D, end: Point2D) {
  const vec = {
    x: end.x - start.x,
    y: end.y - start.y,
  };
  // Dot product is 0 <=> vectors are perpendicular
  return {
    x: vec.y,
    y: -vec.x,
  };
}

/**
 * Squared Euclidean distance between two points
 */
function squaredDistance(start: Point2D, end: Point2D) {
  const dx = start.x - end.x;
  const dy = start.y - end.y;
  return dx * dx + dy * dy;
}
