import React from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { OutputPreview } from 'client/app/apps/workflow-builder/output-preview/OutputPreview';
import Colors from 'common/ui/Colors';

type Props = {
  noOutput: boolean;
};

export const OutputStepPreview = ({ noOutput }: Props) => {
  return (
    <Wrapper noOutput={noOutput}>
      {noOutput ? (
        <NoOutputWrapper>
          <Typography fontStyle="italic">No Preview</Typography>
        </NoOutputWrapper>
      ) : (
        <>
          <OutputPreviewTitle variant="h4">Preview Output</OutputPreviewTitle>
          <OutputPreview />
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'noOutput',
})<{ noOutput: boolean }>(({ theme, noOutput }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(5),
  borderRadius: theme.spacing(0, 3, 3, 0),
  border: `1px solid ${Colors.GREY_30}`,
  borderLeft: 'none',
  backgroundColor: noOutput ? Colors.GREY_10 : 'white',
  justifyContent: noOutput ? 'center' : 'normal',
  width: '100%',
}));

const NoOutputWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const OutputPreviewTitle = styled(Typography)(() => ({
  fontWeight: 600,
}));
