import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import UIBox from 'common/ui/components/UIBox';
import { DialogProps } from 'common/ui/hooks/useDialog';

export const ConflictRefreshDialog = (props: DialogProps<void>) => {
  const onReload = () => window.location.reload();

  return (
    <Dialog open={props.isOpen}>
      <DialogTitle>Failed to save changes</DialogTitle>
      <DialogContent>
        <UIBox padding="bm">
          <DialogContentText>
            The workflow has been edited elsewhere since it was loaded. Reload the
            workflow to fetch the changes and continue editing.
          </DialogContentText>
        </UIBox>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onReload}>
          Reload now
        </Button>
      </DialogActions>
    </Dialog>
  );
};
