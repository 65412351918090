import { isEnabled } from 'common/features/featureTogglesForUI';
import {
  isAutomatedAction,
  isMultiChannelling,
  isParallelDispense,
  isParallelTransfer,
} from 'common/lib/mix';
import {
  Cap,
  Deck,
  ErrorAction,
  Lid,
  MoveLabwareAction,
  Plate,
  PromptAction,
  RefreshTipboxesAction,
  Tipwaste,
  WellLocationOnDeckItem,
} from 'common/types/mix';
import { LiquidMovement, MixPreviewStep } from 'common/types/mixPreview';
import {
  getDeckItemStates,
  updatePlate,
} from 'common/ui/components/simulation-details/mix/deckContents';
import {
  makeLiquidTransferStampingData,
  makeStampingEdge,
} from 'common/ui/components/simulation-details/mix/edge.helpers';
import moveLabware from 'common/ui/components/simulation-details/mix/moveLabware';
import {
  markTipsUsed,
  refreshTipboxes,
  TipboxState,
} from 'common/ui/components/simulation-details/mix/TipboxState';

// Fully describes everything needed to render the MixView at a fixed step.
export type MixState = {
  /**
   * The index of a step applied in the current simulation stage.
   * Indexed from 0 to N where N is the number of steps in the current simulation stage.
   *
   * e.g.
   *
   * "stepIndex is 0" <=> no applied steps
   *
   * "stepIndex is 1 <=> we've applied one step".
   */
  stepIndex: number;
  // The estimated time the robot will spend to get to this step
  timeElapsed: number;
  // Prompts that happened up to current step
  prompts: readonly { stepIndex: number; prompt: PromptAction; automated: boolean }[];
  // Errors that happened up to current step
  errors: readonly { stepIndex: number; error: ErrorAction }[];
  // Contents of the deck at this step.
  // Note the deck contents can be filtered - see `filterState`.
  deck: DeckState;
  // Edges visible at this step.
  // Note the edges can be filtered - see `filterState`.
  edges: readonly Edge[];
  // Locations on the deck to highlight for the current step, such as location
  // of tip cleaner
  highlightedDeckPositionNames: ReadonlySet<string>;
  // Wells involved in a liquid transfer in this step
  affectedWells: readonly WellLocationOnDeckItem[];
};

// Lists tipboxes replaced at a specific step
export type TipboxReplacement = {
  // We highlight this step on the slider, so it's easy for users to tell when
  // they have to replace a tipbox.
  stepIndex: number;
  // The tipboxes (usually just one) that need to replaced at this step.
  replacedTipboxIds: readonly string[];
};

export type DeckItemStateCommonProps = {
  /**
   * Name of a deck position where the deck item currently is,
   * for example "hx://6T-7/1_DWPNestRB".
   * Deck items can physically move around during the execution, therefore
   * we need to know where each item is located at any given step of the execution.
   */
  currentDeckPositionName: string;
  /**
   * The rotation of the plate in the clockwise direction, from 0 to 360.
   */
  currentRotationDegrees: number;
};

export type PlateState = Plate & DeckItemStateCommonProps;

export type TipwasteState = Tipwaste & DeckItemStateCommonProps;

export type LidState = Lid & DeckItemStateCommonProps;

export type CapState = Cap & DeckItemStateCommonProps;

export type DeckItemState =
  | CapState
  | PlateState
  | LidState
  | TipboxState
  | TipwasteState;

// Contents of deck items at this step
export type DeckState = {
  items: readonly DeckItemState[];
};

type EdgeCommon = {
  /**
   * Step at which this edge appears
   */
  stepNumber: number;
};

/**
 * An edge which shows the movement of liquid from one place to another
 */
type LiquidMovementEdge = {
  from: WellLocationOnDeckItem;
  to: WellLocationOnDeckItem;
  /**
   * Object describing the movement of something
   */
  action: LiquidMovement;
  /**
   * Number of the channel.
   */
  channel: number;
  /**
   * List of all channels that aspirated from this well during this step. This
   * is used to determine the start point of the edge within the well.
   */
  channelsAspiratingFromWell: number[];
  /**
   * List of all channels that dispensed to this well during this step. This
   * is used to determine the end point of the edge within the well.
   */
  channelsDispensingToWell: number[];
} & EdgeCommon;

/**
 * Data relevant for the plate stamping action where liquids are being
 * aspirated from `source` plate and then dispensed to `destination` plate
 * using multiple channels simultaneously with equal volumes per tip.
 */
type StampingData = {
  /** Name and location of the source plate in stamping action */
  sourceName: string;
  /** Name and location of the destination plate in stamping action */
  destinationName: string;
  /**
   * The number of channels in the Multi Channel Arm adapter:
   *
   * e.g. 96 adapter or 384 adapter is being used
   */
  channelCount: number;
};

/**
 * Transfer of liquid from one place to another using a tip.
 *
 * Can be a part of stamping action in case of MultiChannel Arm™ (MCA) transfer.
 */
export type LiquidTransferEdge = {
  type: 'liquid_transfer';
  stamping?: StampingData;
} & LiquidMovementEdge;

/**
 * A `LiquidDispenseEdge` shows the provenance of a liquid currently being
 * dispensed at a given location. Similar to `LiquidTransferEdge` this manifests
 * as an arrow from a source to a destination, but in this case the liquid is
 * already in the tip as part of a multidispense. The arrow in the UI is dashed
 * to distinguish it from a `LiquidTransferEdge`.
 */
export type LiquidDispenseEdge = {
  type: 'liquid_dispense';
  stamping?: StampingData;
} & LiquidMovementEdge;

/**
 * A `FiltrationEdge` shows liquid falling from the bottom of a filter (e.g. a
 * robocolumn) into a well. A liquid transfer which involves filtering will be
 * structured with the following (see `SingleChannelTransfer` type for full
 * structure):
 * - `from`: the start location of the liquid
 * - `filter`: the location of the robocolumn
 * - `to[]`: the destination of the liquid after it has fallen out of the
 *   filter. (Note: this array will only ever have one element since
 *   multi-dispenses are split into separate steps)
 *
 * A transfer with `filter` shows as two arrows:
 * - one LiquidTransferEdge/LiquidDispenseEdge from `from` to `filter`
 * - one FiltrationEdge from `from` to `to[0]`
 */
export type FiltrationEdge = {
  type: 'filtration';
} & LiquidMovementEdge;

export type MoveLabwareEdge = {
  type: 'move_plate';
  /**
   * Deck position name (e.g. TecanPos_1_1 or  hx://BioTek_405TS_Landscape_0001/1)
   * the plate is moving from.
   */
  fromDeckPositionName: string;
  /**
   * Deck position name (e.g. TecanPos_1_1 or  hx://BioTek_405TS_Landscape_0001/1)
   * the plate is moving to.
   */
  toDeckPositionName: string;
  deckItemTypes: DeckItemState['kind'][];
} & EdgeCommon;

export type StampingEdge = {
  type: 'stamping';
  /**
   * The number of channels in the Multi Channel Arm adapter:
   *
   * e.g. 96 adapter or 384 adapter is being used
   */
  channelCount: number;
  /**
   * Name and location of the source plate in stamping action
   */
  source: {
    name: string;
    deckPositionName: string;
  };
  /**
   * Name and location of the destination plate in stamping action
   */
  destination: {
    name: string;
    deckPositionName: string;
  };
} & EdgeCommon &
  Pick<LiquidMovementEdge, 'action'>;

/**
 * The MixView shows an arrow for each Edge, which includes liquid transfers and
 * plate movements.
 */
export type Edge = MoveLiquidEdge | MoveLabwareEdge;

/**
 * These are edges which involve moving liquids between plates.
 * This includes some extra information which is being displayed
 * in tooltips while hovering the corresponding arrow.
 */
export type MoveLiquidEdge =
  | LiquidTransferEdge
  | LiquidDispenseEdge
  | FiltrationEdge
  | StampingEdge;

/**
 * Any kind of edge that represents the movement of liquid. Used to determine which edges
 * to show when filtering the preview (e.g. by enabling well provenance view).
 */
export function isLiquidMovementEdge(edge: Edge): edge is MoveLiquidEdge {
  return (
    edge.type === 'liquid_transfer' ||
    edge.type === 'liquid_dispense' ||
    edge.type === 'filtration' ||
    edge.type === 'stamping'
  );
}

function emptyStateForStage(): MixState {
  return {
    stepIndex: 0,
    timeElapsed: 0,
    prompts: [],
    errors: [],
    deck: { items: [] },
    edges: [],
    highlightedDeckPositionNames: new Set(),
    affectedWells: [],
  };
}

/**
 * getInitialState at the very start of a preview, with initial well contents
 * and before any steps happen. You'll usually want to call
 * `getInitialState(mixPreviewFromBackend.deck)`.
 */
export function getInitialState(deck: Deck): MixState {
  return {
    ...emptyStateForStage(),
    deck: { items: getDeckItemStates(deck) },
  };
}

/**
 * restoreState from a previous deck, but before any subsequent steps happen.
 */
export function restoreState(mixState: MixState): MixState {
  return {
    ...emptyStateForStage(),
    deck: mixState.deck,
  };
}

export function isSameLocation(
  locationA: WellLocationOnDeckItem,
  locationB: WellLocationOnDeckItem,
): boolean {
  return (
    locationA.deck_item_id === locationB.deck_item_id &&
    locationA.col === locationB.col &&
    locationA.row === locationB.row
  );
}

/**
 * Apply a single step on top of given state. Does not mutate the state. Returns a copy,
 * uses structural sharing.
 *
 * `noLabwareMovements` is used by the Plate Setup screen because there is not deck to
 * show these movements.
 */
export function applyStep(
  state: MixState,
  nextStepIndex: number,
  step: MixPreviewStep,
  noLabwareMovements?: boolean,
): MixState {
  /**
   * TODO: move from DeckItemState[] to Map<id, DeckItemState> everywhere
   * in this function for more efficiency
   */
  const deckItems = new Map(state.deck.items.map(item => [item.id, item]));
  let newDeckItems: readonly DeckItemState[] = state.deck.items;
  const edgesToAdd: Edge[] = [];
  const highlightedDeckPositionNames: Set<string> = new Set();
  const affectedWells: WellLocationOnDeckItem[] = [];
  let replacedTipboxIds: readonly string[] = [];

  if (isEnabled('TECAN_FLUENT_MCA384') && isMultiChannelling(step)) {
    const stampingEdge = makeStampingEdge({ step, nextStepIndex, deckItems });
    if (
      stampingEdge.destination.deckPositionName !== stampingEdge.source.deckPositionName
    ) {
      edgesToAdd.push(stampingEdge);
    }
  }
  if (isParallelTransfer(step) || isParallelDispense(step)) {
    // Multiple pipettes each doing its thing
    for (const [channelKey, singleChannelStep] of Object.entries(step.channels)) {
      // A channel can only ever be an integer. This is defined in actions.schema.json.
      const channel = Number(channelKey);
      const targetState = singleChannelStep.liquidDestination;
      // It's inefficient to call updatePlate in a loop, because each
      // updatePlate call needs to find the same plate in the plates array
      newDeckItems = updatePlate(newDeckItems, singleChannelStep.from);
      newDeckItems = updatePlate(newDeckItems, targetState);

      const edgeType =
        step.kind === 'parallel_dispense' ? 'liquid_dispense' : 'liquid_transfer';

      const aspLocation = singleChannelStep.from.loc;
      const dspLocation = singleChannelStep.liquidDestination.loc;
      affectedWells.push(aspLocation);
      affectedWells.push(dspLocation);

      // Get list of all channels that are aspirating from this well during this step.
      // This is used by preview for determining the start position of the edge within the
      // source well.
      const channelsAspiratingFromWell = Object.keys(step.channels)
        .filter(channel => isSameLocation(step.channels[channel].from.loc, aspLocation))
        .map(Number);
      // Get list of all channels that are dispensing to this well during this step.
      const channelsDispensingToWell = Object.keys(step.channels)
        .filter(channel =>
          isSameLocation(step.channels[channel].liquidDestination.loc, dspLocation),
        )
        .map(Number);

      const edgeCommonProps = {
        action: singleChannelStep,
        channel,
        channelsAspiratingFromWell,
        channelsDispensingToWell,
        stepNumber: nextStepIndex,
      };

      // If this liquid transfer includes an intermediary 'filter' (i.e. the
      // liquid is passed through a filter), then the tip is dispensing at the
      // `filter` location, not `to`. For Filter Plates, the liquid is already
      // on the filter, so the `from` and `filter` locations are the same.
      // Thus we show up to two edges:
      //   1. going from the `from` location to `to`, if these are different
      //   2. liquid  dropping from the filter.
      if (targetState.filter) {
        if (
          !isSameLocation(
            singleChannelStep.from.loc,
            singleChannelStep.tipDestination.loc,
          )
        ) {
          edgesToAdd.push({
            ...edgeCommonProps,
            type: edgeType,
            from: singleChannelStep.from.loc,
            to: singleChannelStep.tipDestination.loc,
          });
        }
        edgesToAdd.push({
          ...edgeCommonProps,
          type: 'filtration',
          from: singleChannelStep.tipDestination.loc,
          to: singleChannelStep.liquidDestination.loc,
        });
      } else {
        edgesToAdd.push({
          ...edgeCommonProps,
          type: edgeType,
          from: singleChannelStep.from.loc,
          to: singleChannelStep.tipDestination.loc,
          stamping: makeLiquidTransferStampingData(step, deckItems),
        });
      }
    }
  }
  if (step.kind === 'load') {
    const tipLocations = Object.values(step.channels);
    newDeckItems = markTipsUsed(newDeckItems, tipLocations, nextStepIndex);
  }
  let newPrompts = state.prompts;
  if (step.kind === 'prompt') {
    newPrompts = addPrompt(state, nextStepIndex, step, isAutomatedAction(step));
    // Some prompts get the user to update the state of some wells, so update
    // the plates to reflect those changes
    if (step.effects) {
      for (const singleLocationUpdate of Object.values(step.effects)) {
        newDeckItems = updatePlate(newDeckItems, singleLocationUpdate);
      }
    }
  }
  let newErrors = state.errors;
  if (step.kind === 'error') {
    newErrors = [...state.errors, { stepIndex: nextStepIndex, error: step }];
  }
  if (step.kind === 'tipbox_refresh') {
    replacedTipboxIds = step.tipboxes_to_refresh.map(tipboxRefresh => tipboxRefresh.id);
    newDeckItems = refreshTipboxes(newDeckItems, replacedTipboxIds, nextStepIndex);

    newPrompts = addTipboxReplacementPrompt(state, nextStepIndex, step);
  }
  if (step.kind === 'move_plate' && !noLabwareMovements) {
    const { updatedDeckItems, edges } = moveLabware(
      newDeckItems,
      step.effects,
      nextStepIndex,
    );
    newDeckItems = updatedDeckItems;
    edgesToAdd.push(...edges);

    newPrompts = addLabwareMovementPrompt(state, nextStepIndex, step);
  }
  if (step.kind === 'tip_wash') {
    // Highlight each of the deck positions used for washing tips
    for (const location of step.tip_wash_positions) {
      highlightedDeckPositionNames.add(location.position);
    }
  }
  if (step.kind === 'highlight') {
    // Highlight each of the deck positions highlights
    for (const location of step.positions) {
      switch (location.kind) {
        case 'address':
          highlightedDeckPositionNames.add(location.position);
          break;
        case 'rich_annotation_key':
          highlightedDeckPositionNames.add(location.key);
      }
    }

    newPrompts = addPrompt(
      state,
      nextStepIndex,
      {
        kind: 'prompt',
        message: step.message,
        time_estimate: step.time_estimate,
        cumulative_time_estimate: step.cumulative_time_estimate,
      },
      isAutomatedAction(step),
    );
  }
  const newDeck = { ...state.deck, items: newDeckItems };

  return {
    stepIndex: nextStepIndex,
    deck: newDeck,
    // TODO: We need a linked list here to avoid copying the whole list
    //       on each step. Now applying n steps is O(n^2).
    edges: [...state.edges, ...edgesToAdd],
    prompts: newPrompts,
    errors: newErrors,
    timeElapsed: step.cumulative_time_estimate,
    highlightedDeckPositionNames,
    affectedWells,
  };
}

function addTipboxReplacementPrompt(
  state: MixState,
  stepNumber: number,
  action: RefreshTipboxesAction,
) {
  // Replacing a tipbox is always manual action
  const automated = false;
  return addPrompt(
    state,
    stepNumber,
    {
      kind: 'prompt',
      message: 'Tipbox needs replacement',
      time_estimate: action.time_estimate,
      cumulative_time_estimate: action.cumulative_time_estimate,
    },
    automated,
  );
}

function addLabwareMovementPrompt(
  state: MixState,
  stepNumber: number,
  action: MoveLabwareAction,
) {
  const defaultMessage = action.automated ? 'Moving plate' : 'Move plate';
  return addPrompt(
    state,
    stepNumber,
    {
      kind: 'prompt',
      message: action.message || defaultMessage,
      time_estimate: action.time_estimate,
      cumulative_time_estimate: action.cumulative_time_estimate,
    },
    action.automated,
  );
}

function addPrompt(
  state: MixState,
  stepIndex: number,
  prompt: PromptAction,
  automated: boolean,
) {
  return [...state.prompts, { stepIndex, prompt, automated }];
}
