import React, { useState } from 'react';

import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';

import {
  flowRateToResidenceTime,
  flowVelocityToResidenceTime,
  residenceTimeToFlowRate,
  residenceTimeToFlowVelocity,
} from 'common/lib/chromatography';
import { roundMeasurement } from 'common/lib/format';
import InlineHelp from 'common/ui/components/InlineHelp/InlineHelp';
import MeasurementEditor from 'common/ui/components/ParameterEditors/MeasurementEditor';

const RESIDENCE_TIME_UNITS = ['s', 'min'];
const FLOW_RATE_UNITS = ['ul/s'];
const FLOW_VELOCITY_UNITS = ['cm/h'];

type ResidenceTimeInputsProps = {
  isDisabled?: boolean;
  robocolumnVolume?: string;
  residenceTime?: string;
  setResidenceTime: (newResidenceTime?: string) => void;
};

/**
 * Inputs for setting residence time for a given chromatography run. Allows input as
 * residence time, flow rate, or flow velocity. When the user changes one input, the
 * others will automatically be computed.
 */
export function ResidenceTimeInputs({
  isDisabled,
  robocolumnVolume,
  residenceTime,
  setResidenceTime,
}: ResidenceTimeInputsProps) {
  const [flowRate, setFlowRate] = useState<string | undefined>(() =>
    residenceTimeToFlowRate(residenceTime, robocolumnVolume),
  );
  const [flowVelocity, setFlowVelocity] = useState<string | undefined>(() =>
    residenceTimeToFlowVelocity(residenceTime, robocolumnVolume),
  );

  // When user enters a residence time, update the flow rate and flow velocity.
  const handleResidenceTimeChange = (newResidenceTime?: string) => {
    setResidenceTime(newResidenceTime);
    setFlowRate(residenceTimeToFlowRate(newResidenceTime, robocolumnVolume));
    setFlowVelocity(residenceTimeToFlowVelocity(newResidenceTime, robocolumnVolume));
  };

  // When user enters a flow rate, update the residence time and flow velocity
  const handleFlowRateChange = (newFlowRate?: string) => {
    const newResidenceTime = flowRateToResidenceTime(newFlowRate, robocolumnVolume);
    const newFlowVelocity = residenceTimeToFlowVelocity(
      newResidenceTime,
      robocolumnVolume,
    );
    setFlowRate(newFlowRate);
    setResidenceTime(newResidenceTime);
    setFlowVelocity(newFlowVelocity);
  };

  // When user enters a flow rate, update the residence time and flow rate
  const handleFlowVelocityChange = (newFlowVelocity?: string) => {
    const newResidenceTime = flowVelocityToResidenceTime(
      newFlowVelocity,
      robocolumnVolume,
    );
    const newFlowRate = residenceTimeToFlowRate(newResidenceTime, robocolumnVolume);
    setFlowVelocity(newFlowVelocity);
    setResidenceTime(newResidenceTime);
    setFlowRate(newFlowRate);
  };

  // Residence time, flow rate, and flow velocity are computed and can have a lot of
  // decimal places. Show just 1dp.
  const residenceTimeRounded = residenceTime && roundMeasurement(residenceTime, 1);
  const flowRateRounded = flowRate && roundMeasurement(flowRate, 1);
  const flowVelocityRounded = flowVelocity && roundMeasurement(flowVelocity, 1);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="subtitle2">Loading Time</Typography>
        <InlineHelp>
          <p>
            The following calculations are automatically performed for you:
            <ul>
              <li>Residence time (s) = robocolumn volume (l) ÷ flow rate (l/s)</li>
              <li>
                Residence time (s) = robocolumn volume (l) ÷ (flow velocity (m/s) ×
                robocolumn area (m²))
              </li>
              <li>Flow rate (l/s) = robocolumn volume (l) ÷ residence time (s)</li>
              <li>
                Flow velocity (m/s) = (robocolumn volume (l) ÷ residence time (s)) ÷
                robocolumn area (m²)
              </li>
            </ul>
          </p>
        </InlineHelp>
      </Box>
      <Box>
        <InputLabel shrink>Residence Time</InputLabel>
        <MeasurementEditor
          units={RESIDENCE_TIME_UNITS}
          defaultUnit={RESIDENCE_TIME_UNITS[0]}
          value={residenceTimeRounded}
          onChange={handleResidenceTimeChange}
          isDisabled={isDisabled}
          isRequired
        />
      </Box>
      {robocolumnVolume ? (
        <>
          <Box>
            <InputLabel shrink>Volumetric Flow Rate</InputLabel>
            <MeasurementEditor
              value={flowRateRounded}
              units={FLOW_RATE_UNITS}
              defaultUnit={FLOW_RATE_UNITS[0]}
              onChange={handleFlowRateChange}
              isDisabled={isDisabled || !robocolumnVolume}
              isRequired
            />
          </Box>
          <Box>
            <InputLabel shrink>Flow Velocity</InputLabel>
            <MeasurementEditor
              value={flowVelocityRounded}
              units={FLOW_VELOCITY_UNITS}
              defaultUnit={FLOW_VELOCITY_UNITS[0]}
              onChange={handleFlowVelocityChange}
              isDisabled={isDisabled}
              isRequired
            />
          </Box>
        </>
      ) : (
        <FormHelperText disabled>
          Cannot auto-calculate flow rate or velocity since multiple RoboColumns selected
          with different column volumes.
        </FormHelperText>
      )}
    </>
  );
}
