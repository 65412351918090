import React from 'react';

import { NavNode } from 'client/app/components/nav/breadcrumbs/types';
import useBreadcrumbsEntity from 'client/app/components/nav/breadcrumbs/useBreadcrumbsEntity';
import { BreadcrumbsEntityEnum } from 'client/app/gql';

type EntityType = ReturnType<typeof useBreadcrumbsEntity>;

const BreadcrumbsEntityContext = React.createContext<EntityType | null>(null);

export default function CurrentEntityProvider({
  currentEntity = BreadcrumbsEntityEnum.EXPERIMENT,
  children,
}: React.PropsWithChildren<{
  currentEntity?: BreadcrumbsEntityEnum;
}>) {
  const node = { entity: currentEntity } as NavNode;
  return (
    <BreadcrumbsEntityContext.Provider value={useBreadcrumbsEntity(node)}>
      {children}
    </BreadcrumbsEntityContext.Provider>
  );
}

export const useCurrentEntity = <T,>() => React.useContext(BreadcrumbsEntityContext) as T;
